import styled from '@emotion/styled/macro';
import { Upload as Icon } from 'react-feather';

const DropzoneWrapper = styled.div`
  background-color: ${({ isDragActive }) => (isDragActive ? '#f5f5f5' : '#eeeeee')};
  border: ${({ isDragActive }) => (isDragActive ? '2px solid #5664d2' : 'dashed #5664d2 2px')};
  display: flex;
  justify-content: center;
  align-items: ${({ isPolicyValidation, isTemplate }) =>
    (isPolicyValidation || isTemplate) && 'center'};
  height: ${({ isPolicyValidation, isTemplate }) =>
    isPolicyValidation || isTemplate ? '100%' : '250px'};
  min-height: ${({ isPolicyValidation, isTemplate }) =>
    (isPolicyValidation || isTemplate) && '500px'};
  outline: none;
  cursor: pointer;
  border-radius: 2px;

  @media (max-width: 500px) {
    height: ${({ isPolicyValidation, isTemplate }) =>
      !isPolicyValidation && !isTemplate && '200px'};
    min-height: ${({ isPolicyValidation, isTemplate }) =>
      (isPolicyValidation || isTemplate) && '0px'};
  }
`;

const FileItem = styled.div`
  border: 1px solid #eaeaea;
`;

const FileImg = styled.img`
  width: 100%;
  height: 100%;
`;

const TextContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-content: center;
  text-align: center;
`;

const DropzoneContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: sans-serif;
  color: #8075ac;
  padding: 10px;
`;

const IconStyled = styled(Icon)`
  margin-top: 20px;
`;

export default {
  DropzoneWrapper,
  FileItem,
  FileImg,
  TextContainer,
  DropzoneContent,
  IconStyled,
};
