import { Box, Button, Container, Grid, Typography } from '@mui/material';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import styled from '@emotion/styled/macro';
import { useLocation, useNavigate } from 'react-router-dom';
import { TestModeContext } from 'src/context/testModeContext';
import CardComponent from 'src/components/reusableComponents/Card';
import TrafficLight from 'src/components/reusableComponents/TrafficLight/TrafficLight';
import TrafficLightIcon from 'src/components/reusableComponents/TrafficLight/TrafficLightIcon';

const WrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
`;

const Details = () => {
  const navigate = useNavigate();
  const { infoTestMode, infoInsurancePolicy } = TestModeContext();
  const { control_ok, images } = infoTestMode;
  const { file } = infoInsurancePolicy;
  const { state } = useLocation();
  const testModeResult = state !== null && state.testMode;
  const isCedulaValidation = state !== null && state.isCedulaValidation;
  const isPolicyValidation = state !== null && state.isPolicyValidation;

  const handleReturn = () => {
    if (testModeResult) {
      if (isCedulaValidation) {
        navigate('/app/testCedula', { state: { toReturn: true } });
      } else if (isPolicyValidation) {
        navigate('/app/testPolicy', { state: { toReturn: true } });
      } else {
        navigate('/app/testMode', { state: { toReturn: true } });
      }
    } else {
      navigate('/app/transactions');
    }
  };

  const handleClean = () => {
    if (isCedulaValidation) {
      navigate('/app/testCedula');
    } else if (isPolicyValidation) {
      navigate('/app/testPolicy');
    } else {
      navigate('/app/testMode');
    }
  };

  // mock result
  const idOperacion = 1234;

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
      }}
    >
      <Container maxWidth={false}>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {testModeResult && (
                <Typography
                  align="left"
                  color="textPrimary"
                  gutterBottom
                  variant="h4"
                  sx={{
                    mb: '20px',
                  }}
                >
                  Modo Prueba
                </Typography>
              )}
              <WrapperStyled style={{ alignItems: 'center' }}>
                <Typography align="left" color="textPrimary" variant="h5">
                  {testModeResult ? 'Resultado' : `RESULTADO ID OPERACION: ${idOperacion}`}
                </Typography>
                {!isPolicyValidation && <TrafficLightIcon value={control_ok} />}
              </WrapperStyled>
            </Grid>
            {!testModeResult && (
              <Grid container item xs={12} justifyContent="flex-end">
                <Button variant="contained" size="small">
                  Anterior
                </Button>
                <Button variant="contained" size="small" sx={{ marginLeft: '10px' }}>
                  Siguiente
                </Button>
              </Grid>
            )}
            <Grid item xs={12}>
              <WrapperStyled>
                {!isPolicyValidation && <TrafficLight type="obd" value={control_ok} />}
                <Grid container spacing={isPolicyValidation ? 3 : 2}>
                  {testModeResult &&
                    (!isPolicyValidation ? (
                      images
                        .filter((image) => image.src !== '')
                        .map((card) => (
                          <Grid item key={card.id} lg={6} xs={12}>
                            <CardComponent
                              cardData={card}
                              isCedulaValidation={isCedulaValidation}
                            />
                          </Grid>
                        ))
                    ) : (
                      <Grid item lg={6} xs={12}>
                        <CardComponent
                          cardData={file}
                          isCedulaValidation={isCedulaValidation}
                          isPolicyValidation={isPolicyValidation}
                        />
                      </Grid>
                    ))}
                </Grid>
              </WrapperStyled>
            </Grid>
            <Grid
              item
              lg={isPolicyValidation && 6}
              xs={12}
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Button startIcon={<ArrowLeft />} variant="contained" onClick={handleReturn}>
                volver
              </Button>
              <Button variant="contained" onClick={handleClean}>
                limpiar resultados
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Details;
