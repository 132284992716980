export default {
  name_insurance_company: 'SEGUROS DEL ESTADO S.A.',
  type_policy: 'AUTOMÓVILES',
  name_insured: 'VICTOR MANUEL TORRES',
  fecha_expedicion: '30/SEP/1986',
  lugar_expedicion: 'BOGOTA',
  valid_from: '15/03/2021',
  valid_to: '15/03/2022',
  readable_barcode: true,
};
