import { Box, Button, Card } from '@mui/material';
import styled from '@emotion/styled/macro';
import { Trash2 as Icon } from 'react-feather';

const CardStyled = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 2px;
`;

const BoxStyled = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ selfieStyle }) => (selfieStyle ? 'space-between' : 'flex-end')};
  margin: 10px;

  @media (max-width: 500px) {
    margin-top: 10px;
  }
`;

const ButtonStyled = styled(Button)`
  font-size: 14px;
  text-transform: none;
  color: #8075ac;
`;

const IconStyled = styled(Icon)`
  margin-left: 5px;
`;

export default {
  CardStyled,
  BoxStyled,
  ButtonStyled,
  IconStyled,
};
