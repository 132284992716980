export default {
  name_insurance_company: 'Empresa de Seguros',
  type_policy: 'Tipo de Póliza',
  name_insured: 'Nombre del Asegurado',
  fecha_expedicion: 'Fecha de expedición',
  lugar_expedicion: 'Lugar de expedición',
  valid_from: 'Vigencia desde',
  valid_to: 'Vigencia hasta',
  readable_barcode: 'Barcode Legible',
};
