import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { TemplatesContext } from 'src/context/templatesContext';
import PictureAnnotation from 'src/components/templateEdit/PictureAnnotation';
import Stepper from 'src/components/templateEdit/Stepper';
// import ReferenceList from 'src/components/templateEdit/ReferenceList';
import { categories } from 'src/components/templateEdit/data';
import { objectFilter } from 'src/components/templateEdit/helper';
import styles from '../components/templateEdit/styles';
import newImg from 'src/components/templatesSettings/polizacroped.jpg';

const EditTemplate = () => {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { template, setTemplate } = TemplatesContext();
  const { file } = template;

  const [isTemplateEdit, setIsTemplateEdit] = useState(false);
  const [isDocumentEdit, setIsDocumentEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [nameTags, setNameTags] = useState(categories);
  const [availableTags, setAvailableTags] = useState(categories);
  const [annotations, setAnnotations] = useState([]);
  const [activeAnnotation, setActiveAnnotation] = useState(null);
  const [coordinatesDocument, setCoordinatesDocument] = useState([]);
  const [coordinatesTags, setCoordinatesTags] = useState([]);

  // console.log(annotations);

  useEffect(() => {
    const { document, tags } = file.annotations;
    if (document && tags) {
      setCoordinatesDocument(document);
      setCoordinatesTags(tags);
      const totalCoordinates = document.concat(tags);
      setAnnotations(totalCoordinates);
    }
  }, []);

  useEffect(() => {
    const nameNewAnnotation =
      annotations.length !== 0 && annotations[annotations.length - 1].comment;
    if (nameNewAnnotation && !Object.keys(nameTags).includes(nameNewAnnotation)) {
      const newData = annotations.map((item) => {
        if (item.comment !== nameNewAnnotation) {
          return item;
        } else {
          return {
            ...item,
            comment: '',
          };
        }
      });
      setAnnotations(newData);
    }
  }, [annotations]);

  useEffect(() => {
    const filteredTags = objectFilter(
      nameTags,
      (tag) =>
        isDocumentEdit
          ? tag === 'document'
          : tag !== 'document' && !annotations.map(({ comment }) => comment).includes(tag),
      true
    );
    setAvailableTags(filteredTags);
  }, [annotations, nameTags, isDocumentEdit]);

  const handleFinishEdit = () => {
    if (annotations.map(({ comment }) => comment).includes('')) {
      setIsOpen(true);
    } else {
      setIsTemplateEdit(false);
      setIsDocumentEdit(false);
      setActiveAnnotation(null);
      setCoordinatesTags(annotations);
      const totalCoordinates = coordinatesDocument.concat(annotations);
      setAnnotations(totalCoordinates);
      setTemplate({
        ...template,
        file: {
          ...file,
          src: file.srcCrop,
          annotations: totalCoordinates,
        },
      });
    }
  };

  const handleTemplateEdit = () => {
    setIsTemplateEdit(true);
    setIsDocumentEdit(true);
    setAnnotations(coordinatesDocument);
  };

  const handleDocumentEdit = () => {
    setIsDocumentEdit(true);
    setCoordinatesTags(annotations);
    setAnnotations(coordinatesDocument);
  };

  const handleTagsEdit = async () => {
    // const headerConfig = {
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    // };
    // const data = {
    //   image: file.src,
    //   coordinates: annotations,
    // };
    try {
      setIsLoading(true);
      setIsDocumentEdit(false);
      // const response = await http.post('', data, headerConfig);
      const response = newImg;
      setTimeout(() => {
        setTemplate({
          ...template,
          file: {
            ...file,
            srcCrop: response,
            sizeCrop: {
              width: 771,
              height: 818,
            },
          },
        });
        setCoordinatesDocument(annotations);
        setAnnotations(coordinatesTags);
        setIsLoading(false);
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelEdit = () => {
    setIsTemplateEdit(false);
    setIsDocumentEdit(false);
    const { document, tags } = file.annotations;
    if (document && tags) {
      const totalCoordinates = document.concat(tags);
      setCoordinatesDocument(document);
      setCoordinatesTags(tags);
      setAnnotations(totalCoordinates);
      setTemplate({
        ...template,
        file: {
          ...file,
          srcCrop: '',
          sizeCrop: {},
        },
      });
    }
    const OldNameTags = objectFilter(categories, (tag) => (tag.newTag ? tag.newTag !== true : tag));
    setNameTags(OldNameTags);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDelete = () => {
    setIsOpen(false);
    setIsTemplateEdit(false);
    setIsDocumentEdit(false);
    setActiveAnnotation(null);
    const correctAnnotations = annotations.filter(({ comment }) => comment !== '');
    const totalCoordinates = coordinatesDocument.concat(correctAnnotations);
    setCoordinatesTags(correctAnnotations);
    setAnnotations(totalCoordinates);
    setTemplate({
      ...template,
      file: {
        ...file,
        annotations: totalCoordinates,
      },
    });
  };

  return (
    <>
      <Grid container>
        {!isTemplateEdit ? (
          <styles.GridStyled
            item
            xs={12}
            isbuttons="true"
            size={!file.srcCrop ? file.size : file.sizeCrop}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                maxWidth: '150px',
              }}
            >
              <styles.ButtonStyled
                variant="contained"
                disabled={mobileScreen}
                onClick={handleTemplateEdit}
              >
                Editar
              </styles.ButtonStyled>
              {mobileScreen && (
                <Typography variant="body2" color="error" align="center">
                  *Edición no disponible en mobile
                </Typography>
              )}
            </Box>
          </styles.GridStyled>
        ) : (
          <styles.GridStyled item xs={12} size={!file.srcCrop ? file.size : file.sizeCrop}>
            <Stepper
              isLoading={isLoading}
              handleFinishEdit={handleFinishEdit}
              handleCancelEdit={handleCancelEdit}
              handleDocumentEdit={handleDocumentEdit}
              handleTagsEdit={handleTagsEdit}
            />
          </styles.GridStyled>
        )}
        {/* <styles.GridStyled item xs={12}>
        <ReferenceList />
      </styles.GridStyled> */}
        {isLoading ? (
          <styles.GridStyled item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 2,
              }}
            >
              <CircularProgress size={40} />
            </Box>
          </styles.GridStyled>
        ) : (
          <Grid
            item
            xs={12}
            sx={{
              mt: 4,
            }}
          >
            <PictureAnnotation
              image={!file.srcCrop ? file.src : file.srcCrop}
              size={!file.srcCrop ? file.size : file.sizeCrop}
              coordinates={annotations}
              setCoordinates={setAnnotations}
              activeAnnotation={activeAnnotation}
              setActiveAnnotation={setActiveAnnotation}
              availableTags={availableTags}
              nameTags={nameTags}
              setNameTags={setNameTags}
              editTemplate={isTemplateEdit}
              editDocument={isDocumentEdit}
            />
          </Grid>
        )}
      </Grid>
      <Dialog open={isOpen}>
        <DialogTitle sx={{ px: 5, pt: 4, fontSize: '20px' }}>
          Error: Existen campos sin definir
        </DialogTitle>
        <DialogContent
          sx={{
            px: 5,
          }}
        >
          <DialogContentText>
            Todos los campos deben contener al menos una etiqueta. Desea eliminar los campos vacíos
            y guardar los cambios efectuados?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            pr: 3,
            pb: 2,
          }}
        >
          <Button color="error" onClick={handleClose}>
            Cancelar
          </Button>
          <Button onClick={handleDelete}>Eliminar y Guardar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default EditTemplate;
