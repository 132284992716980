const countries = {
  ARGENTINA: {
    value: 'ARG',
  },
  COLOMBIA: {
    value: 'COL',
  },
};

export default countries;
