import { Box, Typography } from '@mui/material';

import { Suspense, useEffect, useState } from 'react';
import http from 'src/utils/api';
import ImageCard from 'src/components/reusableComponents/ImageCard';

function ContentFotos({ idConjuntoImagenes }) {
  const [imgs, updImgs] = useState();

  useEffect(() => {
    const getData = async () => {
      if (idConjuntoImagenes == 0) return;
      try {
        const ep = await http.get(`api/conjuntoimg/${idConjuntoImagenes}`);
        updImgs(ep.imagenes);
        console.log(ep);
      } catch (error) {
        console.log(error);
        updImgs([]);
      }
    };
    getData();
  }, [idConjuntoImagenes]);
  const imgStyle = { width: '100%' };
  return (
    <Box>
      {imgs &&
        imgs.map((img, index) => (
          <Box
            key={`${img.id}-${index}`}
            sx={{ border: '1px solid rgba(224, 224, 224, 1)', borderRadius: 1, m: 2 }}
          >
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                m: 1,
                justifyContent: 'space-between',
              }}
              variant="h6"
            >
              {img.tipo_imagen}
            </Typography>
              <img  style={imgStyle} src={img.base64img} />
          </Box>
        ))}
    </Box>
  );
}

export default ContentFotos;
