import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@mui/material';

const MainLayoutWrapper = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

const MainLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

const MainLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
});

const MainLayout = () => (
  <MainLayoutWrapper>
    <MainLayoutContainer>
      <MainLayoutContent>
        <Outlet />
      </MainLayoutContent>
    </MainLayoutContainer>
  </MainLayoutWrapper>
);

export default MainLayout;
