import PropTypes from 'prop-types';
import XLSX from 'xlsx';
import { Button } from '@mui/material';

function ExportData({ data }) {
  const columnsTitle = ['ID OPERACION', 'TIPO', 'ENDPOINT', 'FECHA', 'CANTIDAD', 'EMPRESA'];
  const excelData = [columnsTitle, ...data];

  const exportFile = () => {
    /* convert state to workbook */
    const ws = XLSX.utils.aoa_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Onboardings');
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, 'ReporteOnboardings.xlsx');
  };

  return (
    <Button
      variant="outlined"
      size="small"
      color="success"
      disabled={!data.length}
      onClick={exportFile}
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        mb: '8px',
        width: '120px',
        p: '5px',
      }}
    >
      <img alt="download_icon" src="/static/images/xlsx.png" width={24} />
      EXPORTAR
    </Button>
  );
}

ExportData.propTypes = {
  data: PropTypes.any,
  user: PropTypes.string,
};

export default ExportData;
