import { Accordion, Box, colors, TextField } from '@mui/material';
import styled from '@emotion/styled/macro';

const GeneralContainer = styled(Box)`
  background-color: #f4f6f8;
  min-height: 100%;
  width: ${({ tablet }) => (tablet ? '100%' : '80%')};
  padding-bottom: 30px;
`;

const DivStyled = styled.div`
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 2px 0 rgb(63 63 68 / 15%);
  overflow: hidden;
  max-height: ${({ isExpanded }) => (isExpanded ? '1000px' : '0px')};
  transition: ${({ isExpanded }) =>
    isExpanded ? 'max-height 1s ease-in-out' : 'max-height 0.5s cubic-bezier(0, 1, 0, 1)'};
`;

const ListStyled = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: ${colors.common.white};
  border-radius: ${({ expandedList, isExpanded }) => (expandedList && isExpanded ? '0px' : '4px')};
  border-top-left-radius: ${({ expandedList, isExpanded }) => expandedList && isExpanded && '4px'};
  border-top-right-radius: ${({ expandedList, isExpanded }) => expandedList && isExpanded && '4px'};
  box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 2px 0 rgb(63 63 68 / 15%);
  justify-content: space-between;
  padding: 20px;
`;

const SubListStyled = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: ${colors.common.white};
  box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 2px 0 rgb(63 63 68 / 15%);
  justify-content: space-between;
  padding: 20px;
`;

const ListItemStyled = styled.li`
  display: flex;
  flex-direction: ${({ mobileConfig }) => (mobileConfig ? 'column' : 'row')};
  width: ${({ itemStyled, mobile }) => (itemStyled || mobile ? '100%' : '40%')};
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ listItemStyled }) => listItemStyled && '20px'};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: ${({ ButtonStyled }) => (ButtonStyled ? '30px' : '20px')};
  margin-bottom: ${({ ButtonStyled }) => !ButtonStyled && '30px'};
`;

const SliderContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${({ mobile }) => (mobile ? '100%' : '50%')};
`;

const AccordionStyled = styled(Accordion)`
  border-radius: 4px;

  .MuiAccordionSummary-root {
    padding: 20px;
  }
  .MuiAccordionSummary-content {
    margin: 0px;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0px;
  }
  .MuiAccordionDetails-root {
    padding: 0px 20px 20px;
  }
`;

const TextFieldStyled = styled(TextField)`
  width: ${({ mobile }) => (mobile === 'true' ? '100%' : '50%')};
`;

export default {
  GeneralContainer,
  DivStyled,
  ListStyled,
  SubListStyled,
  ListItemStyled,
  TextContainer,
  ButtonContainer,
  SliderContainer,
  AccordionStyled,
  TextFieldStyled,
};
