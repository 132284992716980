import { Box, MenuItem, FormControl, Select } from '@mui/material';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dropzone from '../Dropzone';
import { gestures } from './data';
import styles from './styles';

const ImageCard = ({
  imageData,
  accept,
  contextData,
  setContextData,
  isPolicyValidation,
  isTemplate,
  ...rest
}) => {
  const [gesture, setGesture] = useState('');

  useEffect(() => {
    if (imageData.id === 'selfie') {
      setGesture(100);
    } else if (imageData.id === 'selfie_gesture') {
      setGesture(108);
    }
  }, []);

  useEffect(() => {
    if (!isPolicyValidation && !isTemplate) {
      if (imageData.id.includes('selfie')) {
        const updatedImages = contextData.images.reduce((acc, curr) => {
          if (curr.id === imageData.id) {
            const element = curr;
            element.validation = {
              code: gesture,
            };
            acc.push(element);
          } else {
            acc.push(curr);
          }
          return acc;
        }, []);
        setContextData({ ...contextData, images: updatedImages });
      }
    }
  }, [gesture]);

  const handleDelete = () => {
    if (!isPolicyValidation && !isTemplate) {
      const updatedImages = contextData.images.reduce((acc, curr) => {
        if (curr.id === imageData.id) {
          const element = curr;
          element.src = '';
          acc.push(element);
        } else {
          acc.push(curr);
        }
        return acc;
      }, []);
      setContextData({ ...contextData, images: updatedImages });
    } else {
      setContextData({
        ...contextData,
        file: {
          ...contextData.file,
          src: '',
        },
      });
    }
  };

  return (
    <styles.CardStyled {...rest}>
      <Dropzone
        imageFile={imageData}
        accept={accept}
        contextData={contextData}
        setContextData={setContextData}
        isPolicyValidation={isPolicyValidation}
        isTemplate={isTemplate}
      />
      <styles.BoxStyled
        selfieStyle={imageData.id === 'selfie' || imageData.id === 'selfie_gesture'}
      >
        {(imageData.id === 'selfie' || imageData.id === 'selfie_gesture') && (
          <Box sx={{ minWidth: 180, maxWidth: 180 }}>
            <FormControl fullWidth>
              <Select
                id="demo-simple-select"
                value={gesture}
                onChange={(e) => setGesture(e.target.value)}
              >
                {(imageData.id === 'selfie' ? gestures.slice(0, 2) : gestures).map(
                  ({ code, value }) => (
                    <MenuItem key={code} value={code}>
                      {value}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Box>
        )}
        <styles.ButtonStyled onClick={handleDelete}>
          Eliminar
          <styles.IconStyled size="20" />
        </styles.ButtonStyled>
      </styles.BoxStyled>
    </styles.CardStyled>
  );
};

ImageCard.propTypes = {
  imageData: PropTypes.object.isRequired,
  accept: PropTypes.string.isRequired,
  contextData: PropTypes.object.isRequired,
  setContextData: PropTypes.func.isRequired,
  isPolicyValidation: PropTypes.bool.isRequired,
  isTemplate: PropTypes.bool.isRequired,
};

export default ImageCard;
