import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import styles from './styles';

const Dropzone = ({
  imageFile,
  accept,
  contextData,
  setContextData,
  isPolicyValidation,
  isTemplate,
}) => {
  const { state } = useLocation();
  const returnOk = state !== null && state.toReturn;

  useEffect(() => {
    if (!returnOk) {
      if (!isPolicyValidation && !isTemplate) {
        const updatedImages = contextData.images.reduce((acc, curr) => {
          const element = curr;
          element.src = '';
          acc.push(element);
          return acc;
        }, []);
        setContextData({ ...contextData, images: updatedImages });
      } else {
        setContextData({
          ...contextData,
          file: {
            ...contextData.file,
            src: '',
          },
        });
      }
    }
  }, []);

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.map((newFile) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (!isPolicyValidation && !isTemplate) {
            const updatedImages = contextData.images.reduce((acc, curr) => {
              if (curr.id === imageFile.id) {
                const element = curr;
                element.src = e.target.result;
                acc.push(element);
              } else {
                acc.push(curr);
              }
              return acc;
            }, []);
            setContextData({ ...contextData, images: updatedImages });
          } else {
            setContextData({
              ...contextData,
              file: {
                ...contextData.file,
                src: e.target.result,
              },
            });
          }
        };
        reader.readAsDataURL(newFile);
        return newFile;
      });
    },
    [contextData.images, contextData.file]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  });

  return (
    <styles.DropzoneWrapper
      {...getRootProps()}
      isDragActive={isDragActive}
      isPolicyValidation={isPolicyValidation}
      isTemplate={isTemplate}
    >
      <input {...getInputProps()} />
      {imageFile.src ? (
        imageFile.src.includes('application/pdf') ? (
          <Document file={imageFile.src} loading={<CircularProgress size={50} />}>
            <Page pageNumber={1} height={480} />
          </Document>
        ) : (
          <styles.FileItem>
            <styles.FileImg alt={`img - ${imageFile.id}`} src={imageFile.src} />
          </styles.FileItem>
        )
      ) : (
        <styles.TextContainer>
          {isDragActive ? (
            <styles.DropzoneContent>Suelte el archivo aquí</styles.DropzoneContent>
          ) : (
            <styles.DropzoneContent>
              <Typography color="textPrimary" variant="body1">
                {imageFile.title}
              </Typography>
              Arrastre y suelte la imagen aquí o haga clic y seleccione un archivo
              <styles.IconStyled size="20" />
            </styles.DropzoneContent>
          )}
        </styles.TextContainer>
      )}
    </styles.DropzoneWrapper>
  );
};

Dropzone.propTypes = {
  imageFile: PropTypes.object.isRequired,
  accept: PropTypes.string.isRequired,
  contextData: PropTypes.object.isRequired,
  setContextData: PropTypes.func.isRequired,
  isPolicyValidation: PropTypes.bool.isRequired,
  isTemplate: PropTypes.bool.isRequired,
};

export default Dropzone;
