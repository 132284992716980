import PropTypes from 'prop-types';
import Wrapper from './styles';

const Svg = ({ color, size, name, className, handleClick, ...rest }) => {
  if (!name) return null;

  // eslint-disable-next-line no-undef
  const svgDir = require.context('!@svgr/webpack!./svg');
  const Icon = svgDir && svgDir(`./${name}.svg`).default;

  if (!Icon) return null;

  return (
    <Wrapper onClick={handleClick} className={className} size={size} color={color} {...rest}>
      <Icon />
    </Wrapper>
  );
};

Svg.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  handleClick: PropTypes.func,
};

Svg.defaultProps = {
  size: 21,
  handleClick: () => {},
};

export default Svg;
