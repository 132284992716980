export default {
  id_company: '',
  id_clientSettings: '',
  id_companySettings: '',
  config: [
    {
      name: 'general',
      title: 'General',
      variables: [
        {
          id: 'flexible',
          label: 'Modo flexible',
          text: 'En modo flexible se intentarán realizar todos los procesos por más que alguna imagen no cumpla con las validaciones correspondientes. Con esta opción desactivada se omitirán todos los pasos que involucren imágenes marcadas como inválidas para ahorrar tiempo.',
          isActive: false,
          disabled: false,
        },
        {
          id: 'useDocAlign',
          label: 'Usar DocAlign',
          text: 'DocAlign es el módulo que segmenta y encuadra los documentos para mejorar la extracción de datos. Si las imágenes enviadas ya se encuentran alineadas es recomendable desactivar esta opción ya que es costosa en tiempo.',
          isActive: false,
          disabled: false,
        },
        {
          id: 'selfieGetExpressions',
          label: 'Expresiones faciales',
          text: 'Activar para estimar las expresiones faciales en cada selfie. Se retornarán las probabilidades obtenidas para cada una de siete emociones (Enojado, Disgustado, Asustado, Feliz, Triste, Sorprendido y Neutral).',
          isActive: false,
          disabled: false,
        },
      ],
    },
    {
      name: 'validationControl',
      title: 'Control de Validaciones',
      variables: [
        {
          id: 'controlok',
          label: 'Control OK',
          text: 'Activar para devolver en el campo "control_ok" verdadero sí y sólo sí se cumplen los controles que sean seleccionados a continuación o falso en caso contrario. En caso de que esta opción se encuentre desactivada, el campo tendrá valor falso por defecto.',
          isActive: false,
          disabled: false,
        },
      ],
      subVariables: [
        {
          id: 'dni_front',
          label: 'Frente DNI',
          text: 'Controlar que la imagen enviada como frente corresponda con el de un DNI argentino.',
          isActive: false,
          disabled: false,
        },
        {
          id: 'dni_back',
          label: 'Dorso DNI',
          text: 'Controlar que la imagen enviada como dorso corresponda con el de un DNI argentino.',
          isActive: false,
          disabled: false,
        },
        {
          id: 'match',
          label: 'Match facial',
          text: 'Controlar que se cumpla la verificación de identidad para cada selfie contra el rostro del frente del documento.',
          isActive: false,
          disabled: false,
        },
        {
          id: 'proof',
          label: 'Prueba de vida',
          text: 'Controlar que los gestos solicitados para la prueba de vida correspondan a los que se observan en las selfies.',
          isActive: false,
          disabled: false,
        },
        {
          id: 'suspicious',
          label: 'Selfie Sospechosa',
          text: 'Controlar que las selfies no hayan sido marcadas como sospechosas por el sistema de anti-spoofing.',
          isActive: false,
          disabled: false,
        },
        {
          id: 'dni_suspicious',
          label: 'DNI Sospechoso',
          text: 'Controlar que el DNI no haya sido marcado como sospechoso por el sistema de anti-spoofing.',
          isActive: false,
          disabled: false,
        },
        {
          id: 'context_match',
          label: 'Contexto Selfies',
          text: 'Controlar que todas las selfies de la operación correspondan a un mismo contexto.',
          isActive: false,
          disabled: false,
        },
        {
          id: 'readable_barcode',
          label: 'Barcode legible',
          text: 'Controlar que pueda extraerse la información codificada en el código de barras (PDF-417).',
          isActive: false,
          disabled: false,
        },
        {
          id: 'match_front_back',
          label: 'Match de datos',
          text: 'Controlar que el frente y el dorso del DNI tengan información correspondiente a un mismo documento.',
          isActive: false,
          disabled: false,
        },
        {
          id: 'match_ocr_barcode',
          label: 'Match OCR-Barcode',
          text: 'Controlar que la información extraída del documento por Reconocimiento Óptico de Caracteres (OCR) y la que se encuentra codificada en el código de barras (PDF-417) sean consistentes.',
          isActive: false,
          disabled: false,
        },
        {
          id: 'match_front_back_faces',
          label: 'Match de rostros frente-dorso',
          text: 'Controlar que se cumpla el match del rostro de la foto carnet del frente del DNI contra la imagen monocolor modulada en guilloches que figura en el dorso.',
          isActive: false,
          disabled: false,
        },
        {
          id: 'data_validation_renaper',
          label: 'RENAPER - Ultimo ejemplar vigente DNI',
          text: 'Controlar que el DNI recibido corresponda con el último ejemplar vigente emitido por RENAPER. Se hace mediante la cruza de 5 campos de datos extraídos del documento contra la base de datos del Registro Nacional de las Personas.',
          isActive: false,
          disabled: false,
        },
        // {
        //   id: 'save_photos',
        //   label: 'Guardar fotos',
        //   text: 'Permite a Entre Consultas almacenar las imagenes tomadas por los usuarios al momento de hacer el onboarding digital.',
        //   isActive: false,
        //   disabled: false
        // }
      ],
    },
    {
      name: 'advancedSettings',
      title: 'Configuración avanzada',
      variables: [
        {
          id: 'faceTolerance',
          label: 'Tolerancia match facial SELFIES',
          text: 'Tolerancia en la verificación de identidad mediante biometría facial. A mayores valores de tolerancia la validación se vuelve menos estricta, reduciendo la tasa de falsos negativos a expensas de aumentar la tasa de falsos positivos. El valor por defecto es 0,70.',
          value: 0,
        },
        {
          id: 'faceMinSpoofConf',
          label: 'Tolerancia AntiSpoofing SELFIES',
          text: 'Este valor coincide con la confianza mínima que debe haberse obtenido de nuestros modelos de anti-spoofing para que una selfie sea marcada como sospechosa, siendo 0,50 una probabilidad estimada del 50% de que la imagen sea una recaptura y 1,00 una probabilidad del 100%.',
          value: 0,
        },
        {
          id: 'min_spoof_conf',
          label: 'Tolerancia AntiSpoofing DNI',
          text: 'Este valor coincide con la confianza mínima que debe haberse obtenido de nuestros modelos de anti-spoofing para que una imagen de DNI (frente o dorso) sea marcada como sospechosa, siendo 0,50 una probabilidad estimada del 50% de que la imagen sea una recaptura y 1,00 una probabilidad del 100%.',
          value: 0,
        },
        {
          id: 'client_redirect',
          label: 'Client Redirection',
          text: '',
          value: '',
        },
        {
          id: 'webhook_url',
          label: 'Webhook URL',
          text: '',
          value: '',
        },
      ],
    },
  ],
};
