import { isArray } from 'src/utils/helper';

export const objectFilter = (mainObject, filterFunction, filterKey) => {
  const mainArray = Object.keys(mainObject)
    .filter((ObjectKey) => {
      return filterFunction(filterKey ? ObjectKey : mainObject[ObjectKey]);
    })
    .map((ObjectKey) => {
      return { [ObjectKey]: mainObject[ObjectKey] };
    });
  return isArray(mainArray) ? Object.assign(...mainArray) : {};
};
