import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Snackbar,
  TextField,
} from '@mui/material';
import ArrowRight from '@mui/icons-material/ArrowRight';
import styled from '@emotion/styled/macro';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { TestModeContext } from 'src/context/testModeContext';
import ImageCard from 'src/components/reusableComponents/ImageCard';
import api from 'src/components/testModePolicy/data';

const GridStyled = styled(Grid)`
  @media (max-width: 900px) {
    padding: 0 80px;
  }
  @media (max-width: 500px) {
    padding: 0px;
  }
`;

const TextFieldStyled = styled(TextField)`
  .MuiInputBase-input {
    background-color: #fff;
    border-radius: 4px;
  }
`;

const TestModePolicy = () => {
  const { infoInsurancePolicy, setInfoInsurancePolicy } = TestModeContext();
  const { file } = infoInsurancePolicy;
  const [idOperacion, setIdOperacion] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleResult = () => {
    if (!idOperacion) {
      setIsOpen({
        open: true,
        severity: 'error',
        message: 'Debe ingresar un ID de Operación para poder continuar',
      });
      return;
    }
    if (infoInsurancePolicy.file.src === '') {
      setIsOpen({
        open: true,
        severity: 'error',
        message: 'Debe ingresar un archivo para poder continuar',
      });
      return;
    }
    // const headerConfig = {
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    // };
    // const data = {
    //   ...infoInsurancePolicy,
    //   id_op: idOperacion,
    // };
    try {
      setIsLoading(true);
      const response = api;
      setInfoInsurancePolicy({
        ...infoInsurancePolicy,
        file: {
          ...file,
          validation: response,
        },
      });
      navigate('/app/details', {
        state: { testMode: true, isPolicyValidation: true },
      });
    } catch (error) {
      setIsOpen({
        open: true,
        severity: 'error',
        message: 'Ha ocurrido un inconveniente.',
      });
      setIdOperacion('');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
        }}
      >
        <Container maxWidth={false} disableGutters>
          <Box>
            <GridStyled container spacing={3}>
              <Grid item xs={12}>
                <TextFieldStyled
                  id="id_op"
                  name="id_op"
                  type="number"
                  variant="outlined"
                  size="small"
                  placeholder="ID Operación"
                  inputProps={{
                    min: 0,
                  }}
                  value={idOperacion}
                  onChange={(e) => setIdOperacion(e.target.value)}
                />
              </Grid>
              <Grid item md={5} xs={12}>
                <Box>
                  <ImageCard
                    imageData={file}
                    accept={'image/*,.pdf'}
                    contextData={infoInsurancePolicy}
                    setContextData={setInfoInsurancePolicy}
                    isPolicyValidation
                    isTemplate={false}
                  />
                  <Box
                    mt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button endIcon={<ArrowRight />} variant="contained" onClick={handleResult}>
                      siguiente
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </GridStyled>
          </Box>
        </Container>
      </Box>
      <Dialog open={isLoading}>
        <DialogTitle
          sx={{ display: 'flex', justifyContent: 'center', px: 15, pt: 6, fontSize: '30px' }}
        >
          Analizando Póliza
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', justifyContent: 'center', px: 15, pb: 6 }}>
          <CircularProgress size={25} />
        </DialogContent>
      </Dialog>
      {isOpen.open && (
        <Snackbar
          open={isOpen.open}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          onClose={handleClose}
        >
          <Alert
            severity={isOpen.severity}
            variant="filled"
            onClose={handleClose}
            sx={{ width: '100%' }}
          >
            {isOpen.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default TestModePolicy;
