import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Chart from 'src/components/reusableComponents/Chart';
import { Box, Card, CircularProgress, Container, Typography } from '@mui/material';
import { monthlyTotal } from 'src/utils/chart';

const CompanyMovements = ({ data, date, loading, ...rest }) => {
  const [chartData, setChartData] = useState([]);
  const [obdMonthly, setObdMonthly] = useState('');
  // const matches = useMediaQuery('(min-width:768px)');

  useEffect(() => {
    setChartData(data);
    setObdMonthly(monthlyTotal(data));
  }, [data]);

  const area = {
    chart: {
      id: 'spark1',
      group: 'sparks',
      type: 'line',
      height: 50,
      foreColor: '#084c61',
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 2,
        opacity: 0.2,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 1.5,
    },
    markers: {
      size: 0,
    },
    grid: {
      padding: {
        top: 10,
        bottom: 10,
        left: 90,
      },
      borderColor: '#535A6C',
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    colors: ['#084c61'],
    categories: [...Array(date).map((_, i) => i)],
    tooltip: {
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function formatter() {
            return '';
          },
        },
      },
    },
  };

  return (
    <Card
      sx={{
        width: 300,
        backgroundColor: 'transparent',
        border: '1px solid #084c61',
        // margin: '0 auto'
      }}
      {...rest}
    >
      <Container>
        {!chartData.length || loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 2,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              height: {
                xs: 55,
              },
              position: 'relative',
              p: 0,
            }}
          >
            <Typography
              sx={{
                color: '#084c61',
                width: '30%',
                position: 'absolute',
                top: '10%',
                fontSize: '15px',
                fontWeight: 400,
              }}
            >
              {obdMonthly}
              <br />
              Onboardings
            </Typography>
            <Chart options={area} series={chartData} type="line" />
          </Box>
        )}
      </Container>
    </Card>
  );
};

CompanyMovements.propTypes = {
  data: PropTypes.array,
  date: PropTypes.number,
  loading: PropTypes.bool,
};

export default CompanyMovements;
