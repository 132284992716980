import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Collapse, List, ListItem } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useEffect, useState } from 'react';

const NavItem = ({
  href,
  icon: Icon,
  title,
  endIcon,
  options,
  itemActive,
  subItemActive,
  isSettings,
  open,
  onClick,
  onClickSettings,
  ...rest
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const itemsTestMode = items.filter(({ title }) => title === 'Cédula' || title === 'PólizaSeguro');
  const itemsSettings = items.filter(
    ({ title }) => title === 'Parámetros' || title === 'Plantillas'
  );

  const active =
    href && href
      ? !!matchPath(
          {
            path: href,
            end: false,
          },
          location.pathname
        )
      : false;

  useEffect(() => {
    options && setItems(options);
  }, [options]);

  const handleClick = () => {
    if (itemActive) {
      onClick(false);
      onClickSettings(false);
      navigate(href);
    } else if (subItemActive) {
      navigate(href);
    } else {
      if (isSettings) {
        onClickSettings(!open);
      } else {
        onClick(!open);
      }
    }
  };

  return (
    <>
      <ListItem
        disableGutters
        sx={{
          display: 'flex',
          py: 0,
        }}
        {...rest}
      >
        <Button
          sx={{
            color: 'text.secondary',
            fontWeight: 'medium',
            justifyContent: 'flex-start',
            letterSpacing: 0,
            py: 1.25,
            textTransform: 'none',
            width: '100%',
            ...(active && {
              color: 'primary.main',
            }),
            '& svg': {
              mr: 1,
            },
          }}
          onClick={handleClick}
        >
          {Icon && <Icon size="20" />}
          <span style={{ marginLeft: !Icon && !endIcon && '20px' }}>{title}</span>
          {endIcon && (open ? <ExpandLess /> : <ExpandMore />)}
        </Button>
      </ListItem>
      {options && (
        <Collapse in={open}>
          <List>
            {(isSettings ? itemsSettings : itemsTestMode).map(({ href, title }) => (
              <NavItem
                href={href}
                key={title}
                subItemActive
                title={title.replace(/([a-z])([A-Z])/g, '$1 $2')}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
  endIcon: PropTypes.bool,
  options: PropTypes.array,
  itemActive: PropTypes.bool,
  subItemActive: PropTypes.bool,
  isSettings: PropTypes.bool,
  open: PropTypes.bool,
  onClick: PropTypes.func,
  onClickSettings: PropTypes.func,
};

export default NavItem;
