export function getTitleByRoute(routes, route) {
  let titleByRoute = '';
  routes.forEach(({ path, title }) => {
    if (`/app/${path}` === route) {
      titleByRoute = title.replace(/([a-z])([A-Z])/g, '$1 $2');
    }
  });
  return titleByRoute;
}

export const isObject = (obj = {}) =>
  !!(obj && obj.constructor === Object && Object.keys(obj).length);

export const isArray = (arr = []) => !!(arr instanceof Array && arr.length);

export const isBoolean = (bool = false) => !!(typeof bool === 'boolean');

export const parseDate = (date) => {
  const _date = date.replace(', ', '/').split('/');
  const year = _date[2];
  const month = _date[1];
  const monthIndex = month - 1;
  const day = _date[0];
  const _time = _date[3].split(':');
  const hours = _time[0];
  const minutes = _time[1];
  const seconds = _time[2];
  return new Date(year, monthIndex, day, hours, minutes, seconds);
};

export const isInvalidUrl = (url) => {
  const regExp = new RegExp(
    // eslint-disable-next-line
    /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
  );
  return url && !url.match(regExp);
};
