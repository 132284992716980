import PropTypes from 'prop-types';
import { TextField, Autocomplete as InputAutocomplete } from '@mui/material';

const Autocomplete = ({ options, label, placeholder, variant, setValue, value, ...rest }) => {
  return (
    <InputAutocomplete
      {...rest}
      disablePortal
      id="autocomplete-input"
      options={options}
      onChange={(__, newValue) => {
        setValue(newValue);
      }}
      value={value}
      sx={{ width: 300 }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          label={label}
          placeholder={placeholder}
          sx={{
            '.MuiInput-root': {
              ':before': {
                borderColor: 'transparent',
              },
              ':after': {
                border: '0.5px',
              },
            },
          }}
        />
      )}
    />
  );
};

Autocomplete.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  setValue: PropTypes.func,
  value: PropTypes.any,
  variant: PropTypes.string,
};

Autocomplete.defaultProps = {
  label: '',
  placeholder: '',
  options: [],
  variant: 'standard',
  setValue: () => {},
  value: ' ',
};

export default Autocomplete;
