export const formatDataCedula = (idOperacion, infoTestMode) => {
  const { images } = infoTestMode;
  const data = {
    cedula_back: '',
    cedula_front: '',
    id_op: idOperacion,
    selfies: [],
  };
  images
    .filter((image) => image.src !== '')
    .forEach(({ id, src, validation }) => {
      if (!id.includes('selfie')) {
        data[id] = src;
      } else {
        const { code } = validation;
        data.selfies.push({
          code: code,
          id_selfie: id === 'selfie' ? 1 : 2,
          image: src,
        });
      }
    });
  if (data.selfies.length == 0) {
    delete data.selfies;
  }
  return data;
};

export const formatValidationResultCedula = (response, infoTestMode) => {
  const { images } = infoTestMode;
  const result = images.forEach((image) => {
    for (const elementResp in response) {
      infoTestMode.id_op = response['id_op'];
      infoTestMode.type_cedula = response['type_cedula'];
      infoTestMode.control_ok = response['control_ok'];
      if (elementResp === 'cedula_back' || elementResp === 'cedula_front') {
        if (elementResp === image.id) {
          image.img_quality = response[elementResp].img_quality;
          image.validation = {
            cedula_side: response[elementResp].cedula_side,
          };
        }
      }
      if (image.id === 'cedula_front') {
        if (infoTestMode.type_cedula === 1) {
          image.validation = {
            ...image.validation,
            cedula_numero: response['extracted_data'].cedula_numero,
            apellidos: response['extracted_data'].apellidos,
            nombres: response['extracted_data'].nombres,
          };
        }
        if (infoTestMode.type_cedula === 2) {
          image.validation = {
            ...image.validation,
            cedula_numero: response['extracted_data'].cedula_numero,
            apellidos: response['extracted_data'].apellidos,
            nombres: response['extracted_data'].nombres,
            nacionalidad: response['extracted_data'].nacionalidad,
            fecha_nacimiento: response['extracted_data'].fecha_nacimiento,
            lugar_nacimiento: response['extracted_data'].lugar_nacimiento,
            estatura: response['extracted_data'].estatura,
            sexo: response['extracted_data'].sexo,
            rh: response['extracted_data'].rh,
            fecha_lugar_expedicion: response['extracted_data'].fecha_lugar_expedicion,
            fecha_vencimiento: response['extracted_data'].fecha_vencimiento,
          };
        }
        if (infoTestMode.type_cedula === 3) {
          image.validation = {
            ...image.validation,
            cedula_numero: response['extracted_data'].cedula_numero,
            apellidos: response['extracted_data'].apellidos,
            nombres: response['extracted_data'].nombres,
            nacionalidad: response['extracted_data'].nacionalidad,
            fecha_nacimiento: response['extracted_data'].fecha_nacimiento,
            sexo: response['extracted_data'].sexo,
            rh: response['extracted_data'].rh,
            fecha_expedicion: response['extracted_data'].fecha_lugar_expedicion.split(' ')[0],
            fecha_vencimiento: response['extracted_data'].fecha_vencimiento,
          };
        }
      }
      if (image.id === 'cedula_back') {
        if (infoTestMode.type_cedula === 1) {
          image.validation = {
            ...image.validation,
            cedula_numero2: response['extracted_data'].cedula_numero2,
            fecha_nacimiento: response['extracted_data'].fecha_nacimiento,
            lugar_nacimiento: response['extracted_data'].lugar_nacimiento,
            estatura: response['extracted_data'].estatura,
            rh: response['extracted_data'].rh,
            sexo: response['extracted_data'].sexo,
            fecha_lugar_expedicion: response['extracted_data'].fecha_lugar_expedicion,
            readable_barcode: response['readable_barcode'],
            match_ocr_barcode: response['match_ocr_barcode'],
            match_front_back: response['match_front_back'],
            match_front_back_faces: response['match_front_back_faces'],
          };
        }
        if (infoTestMode.type_cedula === 2) {
          image.validation = {
            ...image.validation,
            cedula_numero2: response['extracted_data'].cedula_numero2,
            readable_qr: response['readable_qr'],
            match_ocr_barcode: response['match_ocr_barcode'],
            match_front_back: response['match_front_back'],
            match_front_back_faces: response['match_front_back_faces'],
            codigo_dorso: response['extracted_data'].codigo_dorso,
          };
        }
        if (infoTestMode.type_cedula === 3) {
          image.validation = {
            ...image.validation,
            cedula_numero2: response['extracted_data'].cedula_numero2,
            readable_barcode: response['readable_barcode'],
            match_ocr_barcode: response['match_ocr_barcode'],
            match_front_back: response['match_front_back'],
            match_front_back_faces: response['match_front_back_faces'],
            codigo_dorso: response['extracted_data'].codigo_dorso,
          };
        }
      }
      if (elementResp === 'selfies') {
        response[elementResp].forEach((element) => {
          if (element.id_selfie === 1 && image.id === 'selfie') {
            image.img_quality = element.img_quality;
            image.validation = {
              ...image.validation,
              proof: element.proof,
              match: element.match,
              suspicious: element.suspicious,
              // emotions: element.emotions,
              context_match: response['context_match'],
            };
          }
          if (element.id_selfie === 2 && image.id === 'selfie_gesture') {
            image.img_quality = element.img_quality;
            image.validation = {
              ...image.validation,
              proof: element.proof,
              match: element.match,
              suspicious: element.suspicious,
              // emotions: element.emotions,
              context_match: response['context_match'],
            };
          }
        });
      }
    }
  });
  return result;
};
