import { Box, Container, Grid } from '@mui/material';
import styled from '@emotion/styled/macro';

const GridContainerStyled = styled(Grid)`
  height: 100vh;
  width: 100vw;

  @media (max-width: 500px) {
    height: 100%;
  }
`;

const GridImageStyled = styled(Grid)`
  display: flex;
  justify-content: center;
  background-image: url(${'/static/images/avatars/mujer.jpg'});
  background-position: revert;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 2000px) {
    background-position: right;
  }
`;

const BoxStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  max-width: 400px;
  margin-top: 240px;

  @media (max-width: 500px) {
    margin-top: 0px;
  }
`;

const TitlesBoxStyled = styled(Box)`
  @media (max-width: 500px) {
    display: none;
  }
`;

const LogoEC = styled.img`
  width: 400px;

  @media (max-width: 500px) {
    width: 250px;
    margin-top: 200px;
  }
`;

const GridStyled = styled(Grid)`
  background-color: #f4f6f8;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1025px) {
    background-color: #ffffff;
  }
`;

const FormContainer = styled(Container)`
  background-color: #ffffff;
  padding: 30px 40px 40px 40px;
  border-radius: 16px;

  @media (max-width: 500px) {
    padding: 60px 40px 40px 40px;
  }
`;

const FormTitlesStyled = styled(Box)`
  @media (max-width: 500px) {
    margin-bottom: 30px;
  }
`;

const Logo = styled.img`
  @media (max-width: 800px) {
    display: none;
  }
`;

export default {
  GridContainerStyled,
  GridImageStyled,
  BoxStyled,
  TitlesBoxStyled,
  LogoEC,
  GridStyled,
  FormContainer,
  FormTitlesStyled,
  Logo,
};
