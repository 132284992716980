import { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { calculateIncomesByObd, calculateExpectedNetIncomes } from 'src/utils/chart';
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';

const Incomes = ({ data, loading, ...rest }) => {
  const [incomesByObd, setIncomesByObd] = useState('');
  const [aws, setAws] = useState(471);
  const [expectedNetIncomes, setExpectedNetIncomes] = useState('');

  useEffect(() => {
    setIncomesByObd(calculateIncomesByObd(data));
    setExpectedNetIncomes(calculateExpectedNetIncomes(calculateIncomesByObd(data), aws));
  }, [data]);

  useEffect(() => {
    setExpectedNetIncomes(calculateExpectedNetIncomes(incomesByObd, aws));
  }, [aws]);

  return (
    <Card sx={{ width: { xs: 300, sm: 500, lg: 400 }, margin: '0 auto' }} {...rest}>
      <CardHeader sx={{ textAlign: 'center' }} title="Estimación de ingresos" />
      <Divider />
      <PerfectScrollbar>
        {expectedNetIncomes == '' || loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 2,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Table size="small">
              <TableBody>
                <TableRow hover>
                  <TableCell sx={{ textAlign: 'center' }}>Ingresos por onboardings</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>{incomesByObd}</TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell sx={{ textAlign: 'center' }}>Estimación costos AWS</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    <input type="number" value={aws} onChange={(e) => setAws(e.target.value)} />
                  </TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell sx={{ textAlign: 'center' }}>Ingresos netos previstos</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>{expectedNetIncomes}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        )}
      </PerfectScrollbar>
    </Card>
  );
};

Incomes.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};

export default Incomes;
