export const sum = (a) => a.reduce((acc, curr) => acc + curr, 0);

export const monthlyTotal = (data) => data.reduce((acc, curr) => acc + sum(curr.data), 0);

export const groupByDate = (array) => {
  const month = [...Array(31)].reduce((acc, _, i) => ({ ...acc, [i + 1]: [] }), {});

  return array.reduce((acc, curr) => {
    const { fecha = '' } = curr;
    if (fecha) {
      const date = new Date(fecha).getUTCDate();
      acc[date].push(curr);
    }
    return acc;
  }, month);
};

export const formatTotalByDay = (response) =>
  Object.values(response)
    .map((x) => {
      const [company] = Object.keys(x);
      const [values] = Object.values(x) || [];

      const parsedData = groupByDate(values);

      const tot = Object.values(parsedData).map((value) =>
        value.reduce((acc, curr) => acc + curr.cantidad_onboarding, 0)
      );

      return { name: company, data: tot };
    })
    .sort((x, y) => sum(y.data) - sum(x.data));

export const groupTotal = (data) =>
  data.reduce((acc, curr) => {
    curr.data.forEach((t, i) => {
      if (!acc[i]) {
        acc[i] = t;
      } else {
        acc[i] += t;
      }
    });
    return acc;
  }, []);

export const formatTotalByCompany = (data) => {
  const dataByCompany = data.map((element) => {
    let company;
    let values;
    [company] = Object.keys(element);
    Object.values(element).forEach((x) => {
      [values] = Object.values(x) || [];
    });
    return {
      name: company,
      totalOnboardings: values && values['total_cant'].toFixed(),
      totalEstimado: values && values['suma_totales'].toFixed(2),
    };
  });
  return dataByCompany
    .filter((company) => company.totalOnboardings > 0)
    .sort((x, y) => y.totalOnboardings - x.totalOnboardings);
};

export const totalByCompany = (companiesData) => {
  const total = companiesData.map((company) => company.totalOnboardings);
  return total;
};

export const calculateTotal = (companiesData) => {
  return {
    name: 'Total',
    totalOnboardings: sum(companiesData.map((company) => Number(company.totalOnboardings))),
    totalEstimado: sum(companiesData.map((company) => Number(company.totalEstimado))).toFixed(2),
  };
};

export const calculateIncomesByObd = (companiesData) => {
  return sum(companiesData.map((company) => Number(company.totalEstimado))).toFixed(2);
};

export const calculateExpectedNetIncomes = (incomesByObd, aws) => {
  return (incomesByObd - aws).toFixed(2);
};
