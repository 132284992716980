import {
  Alert,
  Box,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Snackbar,
} from '@mui/material';
import styled from '@emotion/styled/macro';
import { useState, useEffect, Fragment } from 'react';
import Filter from 'src/components/reusableComponents/Filter';
import EndpointCard from 'src/components/stats/EndpointCard';
import { initialState, getText, imagesText } from 'src/components/stats/data';
import http from 'src/utils/api';
import { parseResponse } from 'src/components/stats/helper';
import { format } from 'date-fns';

const BoxStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 900px) {
    align-items: center;
  }
`;

const Stats = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [month, setMonth] = useState(new Date());
  const [year, setYear] = useState(new Date());
  const [company, setCompany] = useState('none');
  const [stats, setStats] = useState(initialState);

  // hardcode
  const companies = [
    {
      value: 'TAP',
      label: 'TAP',
    },
    {
      value: 'Directo',
      label: 'Directo',
    },
    {
      value: 'TEST',
      label: 'TEST',
    },
  ];

  useEffect(() => {
    const role = localStorage.getItem('role');
    if (role === 'ADMIN_EC') setIsAdmin(true);
    if (role === 'ADMIN_EMPRESA') setCompany('NONE');
  }, []);

  useEffect(() => {
    const role = localStorage.getItem('role');
    if (company === 'none' && role === 'ADMIN_EC') {
      setIsOpen({
        open: true,
        severity: 'error',
        message: 'Debe seleccionar una empresa',
      });
      return;
    }
    if (company === 'none') return;
    const getStats = async () => {
      const headerConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const data = {
        month: Number(format(month, 'MM')),
        year: Number(format(year, 'yyyy')),
        empresa: company,
      };
      try {
        setIsLoading(true);
        const response = await http.post('api/endpointcount/', data, headerConfig);
        setStats(parseResponse(response));
      } catch (error) {
        setStats(initialState);
        setIsOpen({
          open: true,
          severity: 'error',
          message: 'Ha ocurrido un inconveniente.',
        });
      } finally {
        setIsLoading(false);
      }
    };
    getStats();
  }, [month, year, company]);

  const getFilterStats = (newDate) => {
    setMonth(newDate);
    setYear(newDate);
  };

  const handleCleanFilter = () => {
    getFilterStats(new Date());
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <BoxStyled>
        <Filter
          type="stats"
          buttonTitle="Limpiar"
          onSelectDate={getFilterStats}
          onClean={handleCleanFilter}
        />
        {isAdmin && (
          <Box sx={{ minWidth: 242, maxWidth: 242, mb: 4 }}>
            <FormControl fullWidth>
              <Select
                id="demo-simple-select"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              >
                <MenuItem value={'none'} disabled>
                  Seleccione una empresa
                </MenuItem>
                {companies.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
      </BoxStyled>
      {company !== 'none' && (
        <Box
          sx={{
            backgroundColor: 'background.default',
            // minHeight: '100%',
            width: '100%',
            pb: 2,
          }}
        >
          <Container disableGutters maxWidth={false}>
            <Grid
              sx={{ width: '100%' }}
              container
              spacing={{ xs: 2, md: 3 }}
              justifyContent="center"
              alignItems="center"
            >
              {stats.map(({ id, label, fields, images, series }) => (
                <Fragment key={id}>
                  <Grid item lg={6} md={12} xl={6} xs={12}>
                    <EndpointCard
                      name={`Endpoint ${id} - Campos`}
                      type="fields"
                      text={getText(id, label)}
                      categories={fields}
                      series={series.fieldsSeries}
                      loading={isLoading}
                    />
                  </Grid>
                  <Grid item lg={6} md={12} xl={6} xs={12}>
                    <EndpointCard
                      name={`Endpoint ${id} - Calidad de imágenes`}
                      type="images"
                      text={imagesText}
                      categories={images}
                      series={series.imagesSeries}
                      loading={isLoading}
                    />
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </Container>
        </Box>
      )}
      {isOpen.open && (
        <Snackbar
          open={isOpen.open}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          onClose={handleClose}
        >
          <Alert
            severity={isOpen.severity}
            variant="filled"
            onClose={handleClose}
            sx={{ width: '100%' }}
          >
            {isOpen.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default Stats;
