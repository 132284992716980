import { Box, Stepper, Step, StepLabel, Button, Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useState } from 'react';
import PropTypes from 'prop-types';

const steps = {
  step_1: {
    label: 'Paso 1: Editar tamaño del documento a analizar',
    text: 'Marque el tamaño del documento que desea sea analizado. Para ello, debe hacer click sobre el rectángulo que delimita el documento.',
  },
  step_2: {
    label: 'Paso 2: Editar etiquetas a analizar',
    text: 'Puede añadir ó eliminar etiquetas. Para ello, debe hacer click sobre la etiqueta que desea modificar.',
  },
};

const StepperComponent = ({
  isLoading,
  handleFinishEdit,
  handleCancelEdit,
  handleDocumentEdit,
  handleTagsEdit,
}) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    handleTagsEdit();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    handleDocumentEdit();
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {Object.entries(steps).map(([step, { label, text }]) => {
          return (
            <Step key={step}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <StepLabel>{label}</StepLabel>
                <Tooltip title={text}>
                  <HelpOutlineIcon color="action" sx={{ fontSize: '15px', marginLeft: '10px' }} />
                </Tooltip>
              </Box>
            </Step>
          );
        })}
      </Stepper>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button variant="text" disabled={activeStep === 0 || isLoading} onClick={handleBack}>
          Atrás
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button
          variant="text"
          color="error"
          sx={{ mr: 2 }}
          disabled={isLoading}
          onClick={handleCancelEdit}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          disabled={isLoading}
          onClick={activeStep === 0 ? handleNext : handleFinishEdit}
        >
          {activeStep === 0 ? 'Siguiente' : 'Guardar'}
        </Button>
      </Box>
    </Box>
  );
};

StepperComponent.propTypes = {
  isLoading: PropTypes.bool,
  handleFinishEdit: PropTypes.func,
  handleCancelEdit: PropTypes.func,
  handleDocumentEdit: PropTypes.func,
  handleTagsEdit: PropTypes.func,
};

export default StepperComponent;
