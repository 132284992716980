/* eslint-disable prettier/prettier */
export const getShapeStyleFunc = () => {
  return (data) => {
    return {
      /** text area **/
      padding: 0, // text padding
      fontSize: 0, // text font size
      fontColor: null, // text font color
      fontBackground: null, // text background color
      fontFamily: null,

      /** stroke style **/
      lineWidth: 3, // stroke width
      shapeBackground: 'hsla(210, 16%, 93%, 0.2)', // background color in the middle of the marker
      // shapeStrokeStyle: (categories[data.comment] && categories[data.comment].color) || '#f8f9fa', // shape stroke color
      shapeStrokeStyle: data.comment ? '#5664d2' : '#d32f2f',
      shadowBlur: 10, // stroke shadow blur
      shapeShadowStyle: 'hsla(210, 9%, 31%, 0.35)', // shape shadow color

      /** transformer style **/
      transformerBackground: '#5c7cfa',
      transformerSize: 10,
    };
  };
};
