import styled from '@emotion/styled/macro';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { imageQuality, controlOk } from './data';

const TrafficLightStyled = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-right: ${({ styleType }) => styleType === 'obd' && '24px'};
  min-width: ${({ styleType }) => (styleType === 'obd' ? '40px' : '10px')};

  @media (max-width: 500px) {
    display: ${({ styleType }) => styleType === 'obd' && 'none'};
    min-height: 10px;
  }
`;

const TrafficLight = ({ type, value }) => (
  <TrafficLightStyled
    styleType={type}
    bgcolor={`alert.${type === 'obd' ? controlOk[value] : imageQuality[value]}`}
  />
);

TrafficLight.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
};

export default TrafficLight;
