import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import PropTypes from 'prop-types';
import { rows } from './data';

const TemplateTable = ({ handleViewTemplate }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>TIPO DE PLANTILLA</TableCell>
            <TableCell align="center">EMPRESA</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(({ id, documentType, company }) => (
            <TableRow key={id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {documentType}
              </TableCell>
              <TableCell align="center">{company}</TableCell>
              <TableCell align="center">
                <Button variant="text" onClick={handleViewTemplate}>
                  Ver
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TemplateTable.propTypes = {
  handleViewTemplate: PropTypes.func.isRequired,
};

export default TemplateTable;
