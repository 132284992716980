import { colors } from '@mui/material';

export const fieldsColors = [colors.green[300], colors.red[300], colors.grey[400]];

export const imagesColors = [
  colors.green[300],
  colors.orange[300],
  colors.red[300],
  colors.grey[400],
];

export const getText = (id, label) => {
  const fieldsText = `Campos: estadísticas de los resultados de validaciones del Endpoint ${id} (${label}). El caso "No Realizado" (o "null" en el campo de la respuesta) corresponde a aquellas validaciones que no fueron llevadas a cabo por falta de datos o por Rechazos en validaciones previas.`;
  return fieldsText;
};

export const imagesText =
  'Estimación automática de la calidad general de la imagen recibida (buena, aceptable o mala). Esta es obtenida a partir de mediciones de características como resolución, foco, sobreexposisión/subexposición, etc. Adicionalmente, en el caso del Frente DNI, la calidad será mala si no logra detectarse con certeza el rostro en la foto carnet. Para selfies, si el rostro detectado es muy pequeño en la imagen, si se encuentra demasiado cerca de los bordes o si la persona no se encuentra de frente a la cámara, la calidad también será mala.';

export const initialState = [
  {
    id: 1,
    label: 'Frente del DNI',
    fields: ['Control OK', 'Dni Front'],
    images: ['Dni Front'],
    series: {
      fieldsSeries: [],
      imagesSeries: [],
    },
  },
  {
    id: 2,
    label: 'Frente y Dorso del DNI',
    fields: [
      'Control OK',
      'Dni Front',
      'Dni Back',
      'Read Barcode',
      'Match FB',
      'Match OCRBC',
      'Match FB Faces',
      'Renaper',
    ],
    images: ['Dni Front', 'Dni Back'],
    series: {
      fieldsSeries: [],
      imagesSeries: [],
    },
  },
  {
    id: 3,
    label: 'Frente del DNI y 1 o 2 Selfies',
    fields: [
      'Control OK',
      'Dni Front',
      'Context Match',
      'Match1',
      'Proof1',
      'Susp1',
      'Match2',
      'Proof2',
      'Susp2',
    ],
    images: ['Dni Front', 'Selfie-1', 'Selfie-2'],
    series: {
      fieldsSeries: [],
      imagesSeries: [],
    },
  },
  {
    id: 4,
    label: 'Frente y Dorso del DNI y 1 o 2 Selfies',
    fields: [
      'Control OK',
      'Dni Front',
      'Dni Back',
      'Read Barcode',
      'Match FB',
      'Match OCRBC',
      'Match FB Faces',
      'Renaper',
      'Context Match',
      'Match1',
      'Proof1',
      'Susp1',
      'Match2',
      'Proof2',
      'Susp2',
    ],
    images: ['Dni Front', 'Dni Back', 'Selfie-1', 'Selfie-2'],
    series: {
      fieldsSeries: [],
      imagesSeries: [],
    },
  },
];
