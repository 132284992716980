import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { calculateTotal } from 'src/utils/chart';
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

const Movements = ({ data, loading, ...rest }) => {
  const [tableData, setTableData] = useState([]);
  const [tableTotal, setTableTotal] = useState('');

  useEffect(() => {
    setTableData(data);
    setTableTotal(calculateTotal(data));
  }, [data]);

  return (
    <Card {...rest}>
      <CardHeader title="Movimientos" />
      <Divider />
      <PerfectScrollbar>
        {!tableData.length || loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 2,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ minWidth: 300 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Empresa</TableCell>
                  <TableCell>Total onboardings</TableCell>
                  <TableCell>Total Estimado</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map(({ name, totalOnboardings, totalEstimado }, i) => (
                  <TableRow hover key={i}>
                    <TableCell>{name}</TableCell>
                    <TableCell>{totalOnboardings}</TableCell>
                    <TableCell>{totalEstimado}</TableCell>
                  </TableRow>
                ))}
                <TableRow hover>
                  <TableCell>{tableTotal.name}</TableCell>
                  <TableCell>{tableTotal.totalOnboardings}</TableCell>
                  <TableCell>{tableTotal.totalEstimado}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        )}
      </PerfectScrollbar>
    </Card>
  );
};

Movements.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};

export default Movements;
