export default {
  id_op: 4,
  type_cedula: 1,
  cedula_front: { cedula_side: 'front', img_quality: 'acceptable' },
  cedula_back: { cedula_side: 'back', img_quality: 'acceptable' },
  selfies: [
    {
      id_selfie: 1,
      code: 100,
      match: true,
      emotions: {
        Enojado: 0.02,
        Disgustado: 0.0,
        Asustado: 0.02,
        Feliz: 0.0,
        Triste: 0.93,
        Sorprendido: 0.0,
        Neutral: 99.03,
      },
      proof: true,
      suspicious: false,
      img_quality: 'good',
    },
    {
      id_selfie: 2,
      code: 108,
      match: true,
      emotions: {
        Enojado: 0.0,
        Disgustado: 0.0,
        Asustado: 0.0,
        Feliz: 99.88,
        Triste: 0.07,
        Sorprendido: 0.0,
        Neutral: 0.05,
      },
      proof: true,
      suspicious: false,
      img_quality: 'good',
    },
  ],
  readable_qr: true,
  readable_barcode: true,
  match_front_back: true,
  match_front_back_faces: true,
  match_ocr_barcode: true,
  context_match: true,
  extracted_data: {
    nombres: 'CARLA ANTONELA',
    apellidos: 'PEREZ',
    cedula_numero: '12546987',
    cedula_numero2: '12546987',
    sexo: 'F',
    rh: 'A+',
    estatura: '1.71',
    fecha_nacimiento: '08/10/1956',
    fecha_vencimiento: '01/10/2028',
    nacionalidad: null,
    lugar_nacimiento: 'NACINEN CORDOBA',
    fecha_lugar_expedicion: '30/SEP/1986 BOGOTA',
    codigo_dorso:
      '9112045 PEREZ<<CARLA<ANTONELA<<<<<<<<<< IDARG361 F2810010ARG<<<<<<<<<<<8 44050<5<<<<<<<<<<<<<<<',
  },
  control_ok: true,
  description: '12546987',
};
