export const codes_responses = {
  img_quality: 'CALIDAD DE IMAGEN',
  match: 'MATCH FACIAL',
  emotions: 'EMOCIONES',
  proof: 'PRUEBA DE VIDA',
  suspicious: 'SOSPECHOSA',
  readable_barcode: 'CÓDIGO DE BARRAS LEGIBLE',
  context_match: 'MATCH CONTEXTUAL',
  match_front_back: 'MATCH DE DATOS FRENTE-DORSO',
  match_front_back_faces: 'MATCH FACIAL FRENTE-DORSO',
  match_ocr_barcode: 'MATCH OCR - CÓDIGO DE BARRAS',
  data_validation_renaper: 'VALIDACIÓN DE DATOS CONTRA RENAPER',
  extracted_data: 'DATOS EXTRAÍDOS',
  control_ok: 'CONTROL OK',
  description: 'DESCRIPCIÓN',
  dni_front: 'DNI FRENTE',
  dni_back: 'DNI DORSO',
  dni_side: 'DETECCIÓN',
  data_validation_renaper_response: 'RESPUESTA RENAPER',
  selfies: 'SELFIES',
  code: 'CÓDIGO',
  id_selfie: 'SELFIE ID',
};

/**
 * @name groupById
 * @param {Array} response - [{},{}, {date: string, endpoint: int, id: int, id_empresa: int, id_op: int, responseJson: object}]
 * @returns {Array} - [ { id_op: int, data: [{response}] } ]
 * @description - receives api's response and group it by 'id_op'
 */
const groupById = (response = []) =>
  response.reduce((acc, curr) => {
    const transaction = acc.find(({ id_op }) => id_op === curr.id_op);
    if (!transaction) {
      acc.push({ id_op: curr.id_op, data: [curr] });
    } else {
      transaction.data.push(curr);
    }
    return acc;
  }, []);

/**
 * @name addControlOk
 * @param {Object} data - { date: string, endpoint: int, id: int, id_empresa: int, id_op: int, responseJson: object }
 * @returns {Object}
 * @description - returns a new object (same as 'data') with key 'control_ok' added
 */
const addControlOk = (data = {}) => {
  const { responseJson = {}, id = '', endpoint = '', date = '', id_empresa = '' } = data;
  const { control_ok = 'false' } = responseJson;
  return {
    id,
    endpoint,
    id_empresa,
    control_ok,
    date,
    details: responseJson,
  };
};

/**
 * @name parsedEndpointData
 * @param {Array} transactions - Array of objects with the same id_op value. Each object represent a transaction
 * @returns {Array} - [{ control_ok: bool, date: string, details: object, endpoint: int, id: int }]
 * @description - receives an array of objects (transactions). Adds to each transaction the 'id_op' key and
 * returns a new array of transactions sorted from highest 'id' to lowest
 */
const parsedEndpointData = (transactions = []) =>
  transactions.map((obd) => addControlOk(obd)).sort((a, b) => b.id - a.id);

/**
 * @name getDni
 * @param {Array} operations
 * @returns {String}
 * @description returns dni number of the last transaction. IMPORTANT: If one 'id_op' contains more than one dni
 * number it will only consider the last one.
 */
const getDni = (operations = []) => {
  const [lastOperation = {}] = operations.filter(({ endpoint }) => endpoint === 2).reverse();

  const {
    responseJson: { extracted_data = {} },
  } = lastOperation;

  const { dni_numero = '' } = extracted_data;

  return dni_numero;
};

const getDate = (operations = []) => {
  const [lastOperation = {}] = operations.reverse();

  const { date = '' } = lastOperation;

  return new Date(date);
};

/**
 * @name parseResponse
 * @param {Array} response - [{}]
 * @param {string} response.date
 * @param {number} response.endpoint
 * @param {number} response.id
 * @param {number} response.id_empresa
 * @param {number} response.id_op
 * @param {Object} response.responseJson
 * @description receives api's response, group transactions by 'id_op' and returns a new array of objects
 * with the following structure: 
 * {id_op: number,
    dni: string,
    quantity: number,
    data: array}
 * @returns {Object} {id_op: int, dni: string, quantity: int, data: array}
 */
export const parseResponse = (response = []) => {
  const groupedById = groupById(response) || [];
  return (
    groupedById &&
    groupedById
      .map(({ data = [], id_op = 0 }) => {
        const transactions = parsedEndpointData(data) || [];

        let dni;
        let date;
        try {
          dni = getDni(data);
        } catch (error) {
          dni = '';
        }
        try {
          date = getDate(data);
        } catch (error) {
          date = '';
        }
        return {
          id_op,
          date: date,
          dni: dni,
          quantity: transactions.length,
          data: transactions,
        };
      })
      .sort((a, b) => b.date - a.date)
  );
};
