/* eslint-disable prettier/prettier */
/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Tooltip,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import { objectFilter } from '../../helper';
import styles from './styles';

export const getInputElement = (
  availableTags,
  tags,
  setTags,
  isOpen,
  setIsOpen,
  isError,
  setIsError,
  newTag,
  setNewTag,
  editDocument
) => {
  return (value, onChange, onDelete) => {
    const handleOpen = () => {
      setIsOpen(true);
    };

    const handleClose = () => {
      setIsOpen(false);
    };

    const handleAddTag = () => {
      const nameTags = Object.values(tags).map(({ name }) => name);
      if (nameTags.includes(newTag)) {
        setIsError(true);
      } else {
        setIsError(false);
        const newNameTags = Object.assign({}, tags);
        newNameTags[newTag] = {
          name: newTag,
          newTag: true,
        };
        setTags(newNameTags);
        onChange(`${newTag}`);
        setNewTag('');
        handleClose();
      }
    };

    const handleDeleteTag = (name) => {
      const OldNameTags = objectFilter(tags, (tag) => tag !== name, true);
      setTags(OldNameTags);
    };

    return (
      <>
        <styles.BoxStyled>
          <FormControl>
            <styles.SelectStyled
              editdocument={`${editDocument}`}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              renderValue={(value) => {
                return <>{tags[value] && tags[value].name}</>;
              }}
            >
              {!editDocument && (
                <styles.MenuItemStyled>
                  <Button
                    variant="outlined"
                    startIcon={<AddCircleOutlineIcon />}
                    sx={{ textTransform: 'none' }}
                    onClick={handleOpen}
                  >
                    Nueva etiqueta
                  </Button>
                </styles.MenuItemStyled>
              )}
              {Object.entries(availableTags)
                .sort(([, { name: a }], [, { name: b }]) => a && b && a.localeCompare(b))
                .map(([tag, { name, newTag }]) => (
                  <MenuItem key={tag} value={tag}>
                    <ListItemText primary={name} />
                    {newTag && (
                      <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                        <IconButton sx={{ p: 0 }} onClick={() => handleDeleteTag(tag)}>
                          <CancelIcon size="23" color="error" />
                        </IconButton>
                      </ListItemIcon>
                    )}
                  </MenuItem>
                ))}
            </styles.SelectStyled>
          </FormControl>
          {!editDocument && (
            <Tooltip title="Eliminar" placement="bottom">
              <styles.ButtonStyled onClick={onDelete}>
                <styles.IconStyled size="23" />
              </styles.ButtonStyled>
            </Tooltip>
          )}
        </styles.BoxStyled>
        <Dialog open={isOpen}>
          <DialogTitle
            sx={{ display: 'flex', justifyContent: 'center', px: 15, pt: 6, fontSize: '30px' }}
          >
            Añadir etiqueta
          </DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              px: 15,
              pb: 6,
            }}
          >
            <DialogContentText>
              A continuación escriba el nombre de la nueva etiqueta:
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="tag"
              type="text"
              fullWidth
              variant="standard"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value.toUpperCase())}
            />
            {isError && (
              <DialogContentText variant="body2" color="error">
                Ya existe una etiqueta con ese nombre.
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleAddTag}>Guardar</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };
};
