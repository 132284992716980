import { Grid, Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';
import CheckContained from '@mui/icons-material/CheckCircle';
import CloseContained from '@mui/icons-material/Cancel';
import PropTypes from 'prop-types';
import propertyValues from './dataDni';
import propertyValuesCedula from './dataCedula';
import propertyValuesPolicy from './dataPolicy';
import TrafficLight from '../TrafficLight/TrafficLight';
import styles from './styles';
import { isBoolean } from 'src/utils/helper';

const CardComponent = ({ cardData, isCedulaValidation, isPolicyValidation, ...rest }) => {
  const { title, src, id, img_quality: quality, validation } = cardData;

  const Result = () => {
    const property = Object.keys(validation);
    const propertyValue = !isPolicyValidation
      ? (isCedulaValidation ? propertyValuesCedula : propertyValues).find(
          (element) => element.id === cardData.id
        ).properties
      : propertyValuesPolicy;

    const stylesGrid = (prop) => {
      if (prop === 'codigo_dorso') return 0;
      return 6;
    };

    const suspiciousIcon = (prop, value) => {
      if (prop === 'suspicious' || prop === 'ban_list_hit') return !value;
      return value;
    };

    const suspiciousValue = (prop, value) => {
      if (prop === 'suspicious' || prop === 'ban_list_hit') {
        if (value) {
          value = 'Si';
        } else {
          value = 'No';
        }
      }
      return value;
    };

    return (
      <styles.GridStyled container spacing={2}>
        {property.map((element) => (
          <Grid item xs={stylesGrid(element)} key={element}>
            <Typography color="textSecondary" variant="h6">
              {`${propertyValue[element]}:`}
            </Typography>
            <Typography color="textPrimary" variant="h6">
              {isBoolean(validation[element]) ? (
                <>
                  {suspiciousIcon(element, validation[element]) ? (
                    <styles.BoxIconStyled>
                      {suspiciousValue(element, validation[element]).toString()}
                      <CheckContained style={{ color: green[500] }} />
                    </styles.BoxIconStyled>
                  ) : (
                    <styles.BoxIconStyled>
                      {suspiciousValue(element, validation[element]).toString()}
                      <CloseContained style={{ color: red[500] }} />
                    </styles.BoxIconStyled>
                  )}
                </>
              ) : (
                <Typography variant="body2" component="span">
                  {validation[element] !== '' && validation[element] !== null ? (
                    validation[element]
                  ) : (
                    <em>sin datos</em>
                  )}
                </Typography>
              )}
            </Typography>
          </Grid>
        ))}
      </styles.GridStyled>
    );
  };

  return (
    <styles.CardStyled {...rest}>
      <styles.BoxStyled>
        <styles.CardHeaderStyled title={title} />
        <styles.WrapperStyled>
          {!isPolicyValidation && (
            <styles.ImageContainer>
              {src && <styles.Image alt={`img - ${id}`} src={src} />}
            </styles.ImageContainer>
          )}
          <Result />
        </styles.WrapperStyled>
      </styles.BoxStyled>
      {!isPolicyValidation && <TrafficLight type="image" value={quality} />}
    </styles.CardStyled>
  );
};

CardComponent.propTypes = {
  cardData: PropTypes.object.isRequired,
  isCedulaValidation: PropTypes.bool,
  isPolicyValidation: PropTypes.bool,
};

export default CardComponent;
