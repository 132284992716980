import { Box, Button, Grid } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from 'react-router-dom';
import { TemplatesContext } from 'src/context/templatesContext';
// import { api } from 'src/components/templatesSettings/api';
import { apiCrop } from 'src/components/templatesSettings/api';
import Table from 'src/components/templatesSettings/Table';

const TemplatesSettings = () => {
  const { setTemplate } = TemplatesContext();
  const navigate = useNavigate();

  const handleAddTemplate = () => {
    setTemplate(apiCrop);
    navigate('/app/addTemplate');
  };

  const handleViewTemplate = () => {
    setTemplate(apiCrop);
    navigate('/app/editTemplate');
  };

  return (
    <>
      <Grid>
        <Grid container item xl={6} md={8} xs={12} justifyContent="flex-end">
          <Box mb={2}>
            <Button
              variant="contained"
              startIcon={<AddCircleOutlineIcon />}
              onClick={handleAddTemplate}
            >
              Añadir
            </Button>
          </Box>
        </Grid>
        <Grid item xl={6} md={8} xs={12}>
          <Table handleViewTemplate={handleViewTemplate} />
        </Grid>
      </Grid>
    </>
  );
};

export default TemplatesSettings;
