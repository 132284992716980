import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import privateRoutes from 'src/routes/privateRoutes';
import { getTitleByRoute } from 'src/utils/helper';

const HelmetComponent = ({ pathName }) => (
  <Helmet>
    <title>{`${getTitleByRoute(privateRoutes, pathName)} | Onboarding Digital`}</title>
  </Helmet>
);

HelmetComponent.propTypes = {
  pathName: PropTypes.string.isRequired,
};

export default HelmetComponent;
