export const parseResponse = (response) => {
  const { Endpoint1, Endpoint2, Endpoint3, Endpoint4 } = response;
  return [
    {
      id: 1,
      label: 'Frente del DNI',
      fields: ['Control OK', 'Dni Front'],
      images: ['Dni Front'],
      series: {
        fieldsSeries: [
          {
            name: 'Ok',
            data: [Endpoint1.controloktrue, Endpoint1.front_front],
          },
          {
            name: 'Rechazado',
            data: [Endpoint1.controlokfalse, Endpoint1.front_back],
          },
          {
            name: 'No realizado',
            data: [0, Endpoint1.front_null],
          },
        ],
        imagesSeries: [
          {
            name: 'Buena',
            data: [Endpoint1.front_qual_good],
          },
          {
            name: 'Aceptable',
            data: [Endpoint1.front_qual_acceptable],
          },
          {
            name: 'Mala',
            data: [Endpoint1.front_qual_bad],
          },
          {
            name: 'No realizado',
            data: [Endpoint1.front_qual_null],
          },
        ],
      },
    },
    {
      id: 2,
      label: 'Frente y Dorso del DNI',
      fields: [
        'Control OK',
        'Dni Front',
        'Dni Back',
        'Read Barcode',
        'Match FB',
        'Match OCRBC',
        'Match FB Faces',
        'Renaper',
      ],
      images: ['Dni Front', 'Dni Back'],
      series: {
        fieldsSeries: [
          {
            name: 'Ok',
            data: [
              Endpoint2.controloktrue,
              Endpoint2.front_front,
              Endpoint2.back_back,
              Endpoint2.read_bc_true,
              Endpoint2.match_fb_true,
              Endpoint2.match_ocrbc_true,
              Endpoint2.match_fb_faces_true,
              Endpoint2.renaper_true,
            ],
          },
          {
            name: 'Rechazado',
            data: [
              Endpoint2.controlokfalse,
              Endpoint2.front_back,
              Endpoint2.back_front,
              Endpoint2.read_bc_false,
              Endpoint2.match_fb_false,
              Endpoint2.match_ocrbc_false,
              Endpoint2.match_fb_faces_false,
              Endpoint2.renaper_false,
            ],
          },
          {
            name: 'No realizado',
            data: [
              0,
              Endpoint2.front_null,
              Endpoint2.back_null,
              Endpoint2.read_bc_null,
              Endpoint2.match_fb_null,
              Endpoint2.match_ocrbc_null,
              Endpoint2.match_fb_faces_null,
              Endpoint2.renaper_null,
            ],
          },
        ],
        imagesSeries: [
          {
            name: 'Buena',
            data: [Endpoint2.front_qual_good, Endpoint2.back_qual_good],
          },
          {
            name: 'Aceptable',
            data: [Endpoint2.front_qual_acceptable, Endpoint2.back_qual_acceptable],
          },
          {
            name: 'Mala',
            data: [Endpoint2.front_qual_bad, Endpoint2.back_qual_bad],
          },
          {
            name: 'No realizado',
            data: [Endpoint2.front_qual_null, Endpoint2.back_qual_null],
          },
        ],
      },
    },
    {
      id: 3,
      label: 'Frente del DNI y 1 o 2 Selfies',
      fields: [
        'Control OK',
        'Dni Front',
        'Context Match',
        'Match1',
        'Proof1',
        'Susp1',
        'Match2',
        'Proof2',
        'Susp2',
      ],
      images: ['Dni Front', 'Selfie-1', 'Selfie-2'],
      series: {
        fieldsSeries: [
          {
            name: 'Ok',
            data: [
              Endpoint3.controloktrue,
              Endpoint3.front_front,
              Endpoint3.context_true,
              Endpoint3.match1_true,
              Endpoint3.proof1_true,
              Endpoint3.susp1_false,
              Endpoint3.match2_true,
              Endpoint3.proof2_true,
              Endpoint3.susp2_false,
            ],
          },
          {
            name: 'Rechazado',
            data: [
              Endpoint3.controlokfalse,
              Endpoint3.front_back,
              Endpoint3.context_false,
              Endpoint3.match1_false,
              Endpoint3.proof1_false,
              Endpoint3.susp1_true,
              Endpoint3.match2_false,
              Endpoint3.proof2_false,
              Endpoint3.susp2_true,
            ],
          },
          {
            name: 'No realizado',
            data: [
              Endpoint3.front_null,
              Endpoint3.context_null,
              Endpoint3.match1_null,
              Endpoint3.proof1_null,
              Endpoint3.susp1_null,
              Endpoint3.match2_null,
              Endpoint3.proof2_null,
              Endpoint3.susp2_null,
            ],
          },
        ],
        imagesSeries: [
          {
            name: 'Buena',
            data: [Endpoint3.front_qual_good, Endpoint3.qual1_good, Endpoint3.qual2_good],
          },
          {
            name: 'Aceptable',
            data: [
              Endpoint3.front_qual_acceptable,
              Endpoint3.qual1_aceptable,
              Endpoint3.qual2_aceptable,
            ],
          },
          {
            name: 'Mala',
            data: [Endpoint3.front_qual_bad, Endpoint3.qual1_bad, Endpoint3.qual2_bad],
          },
          {
            name: 'No realizado',
            data: [Endpoint3.front_qual_null, Endpoint3.qual1_null, Endpoint3.qual2_null],
          },
        ],
      },
    },
    {
      id: 4,
      label: 'Frente y Dorso del DNI y 1 o 2 Selfies',
      fields: [
        'Control OK',
        'Dni Front',
        'Dni Back',
        'Read Barcode',
        'Match FB',
        'Match OCRBC',
        'Match FB Faces',
        'Renaper',
        'Context Match',
        'Match1',
        'Proof1',
        'Susp1',
        'Match2',
        'Proof2',
        'Susp2',
      ],
      images: ['Dni Front', 'Dni Back', 'Selfie-1', 'Selfie-2'],
      series: {
        fieldsSeries: [
          {
            name: 'Ok',
            data: [
              Endpoint4.controloktrue,
              Endpoint4.front_front,
              Endpoint4.back_back,
              Endpoint4.read_bc_true,
              Endpoint4.match_fb_true,
              Endpoint4.match_ocrbc_true,
              Endpoint4.match_fb_faces_true,
              Endpoint4.renaper_true,
              Endpoint4.context_true,
              Endpoint4.match1_true,
              Endpoint4.proof1_true,
              Endpoint4.susp1_false,
              Endpoint4.match2_true,
              Endpoint4.proof2_true,
              Endpoint4.susp2_false,
            ],
          },
          {
            name: 'Rechazado',
            data: [
              Endpoint4.controlokfalse,
              Endpoint4.front_back,
              Endpoint4.back_front,
              Endpoint4.read_bc_false,
              Endpoint4.match_fb_false,
              Endpoint4.match_ocrbc_false,
              Endpoint4.match_fb_faces_false,
              Endpoint4.renaper_false,
              Endpoint4.context_false,
              Endpoint4.match1_false,
              Endpoint4.proof1_false,
              Endpoint4.susp1_true,
              Endpoint4.match2_false,
              Endpoint4.proof2_false,
              Endpoint4.susp2_true,
            ],
          },
          {
            name: 'No realizado',
            data: [
              0,
              Endpoint4.front_null,
              Endpoint4.back_null,
              Endpoint4.read_bc_null,
              Endpoint4.match_fb_null,
              Endpoint4.match_ocrbc_null,
              Endpoint4.match_fb_faces_null,
              Endpoint4.renaper_null,
              Endpoint4.context_null,
              Endpoint4.match1_null,
              Endpoint4.proof1_null,
              Endpoint4.susp1_null,
              Endpoint4.match2_null,
              Endpoint4.proof2_null,
              Endpoint4.susp2_null,
            ],
          },
        ],
        imagesSeries: [
          {
            name: 'Buena',
            data: [
              Endpoint4.front_qual_good,
              Endpoint4.back_qual_good,
              Endpoint4.qual1_good,
              Endpoint4.qual2_good,
            ],
          },
          {
            name: 'Aceptable',
            data: [
              Endpoint4.front_qual_acceptable,
              Endpoint4.back_qual_acceptable,
              Endpoint4.qual1_aceptable,
              Endpoint4.qual2_aceptable,
            ],
          },
          {
            name: 'Mala',
            data: [
              Endpoint4.front_qual_bad,
              Endpoint4.back_qual_bad,
              Endpoint4.qual1_bad,
              Endpoint4.qual2_bad,
            ],
          },
          {
            name: 'No realizado',
            data: [
              Endpoint4.front_qual_null,
              Endpoint4.back_qual_null,
              Endpoint4.qual1_null,
              Endpoint4.qual2_null,
            ],
          },
        ],
      },
    },
  ];
};
