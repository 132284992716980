import { useState } from 'react';
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateRangePicker from '@mui/lab/DateRangePicker';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import DatePicker from '@mui/lab/DatePicker';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import esLocale from 'date-fns/locale/es';
import enLocale from 'date-fns/locale/en-US';
import { isValid } from 'date-fns';
import PropTypes from 'prop-types';
import styles from './styles';

const localeMap = {
  en: enLocale,
  es: esLocale,
};

// const maskMap = {
//   en: '__/__/____',
//   es: '__/__/____',
// };

const Filter = ({ type, buttonTitle, onSelectDate, onSelectDateAdv, onClean }) => {
  const [locale] = useState('es');
  const [now, setNow] = useState(new Date());
  const [fromToDate, setFromToDate] = useState([null, null]);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const handleChangeDate = (newValue) => {
    setNow(newValue);
    if (isValid(newValue)) {
      onSelectDate(newValue);
      setFromToDate([null, null]);
    }
  };

  const handleChangeFromToDate = (newValue) => {
    setFromToDate(newValue);
  };

  const handleDisabledButton = () => fromToDate.includes(null);

  const handleClick = () => {
    if (!fromToDate.includes(null)) {
      onSelectDateAdv(fromToDate);
      setNow(null);
    }
  };

  const handleClean = () => {
    setNow(type !== 'stats' && type !== 'dashboard' ? null : new Date());
    setFromToDate([null, null]);
    onClean();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[locale]}>
      <styles.Container>
        <styles.FilterWrapper>
          <DatePicker
            openTo="year"
            views={['year', 'month']}
            label="Mes y año"
            // disabled={}
            minDate={new Date('2000-01-01')}
            maxDate={new Date()}
            value={now}
            onChange={handleChangeDate}
            renderInput={(params) => <TextField {...params} helperText={null} />}
          />
          {type !== 'stats' && type !== 'dashboard' ? (
            <>
              <Box sx={{ ml: isDesktop && 3, mt: !isDesktop && 5, position: 'relative' }}>
                <Typography
                  color="textPrimary"
                  variant="body1"
                  sx={{ fontSize: 14, position: 'absolute', top: '-30px' }}
                >
                  Busqueda avanzada:
                </Typography>
                <DateRangePicker
                  toolbarTitle="Seleccionar intervalo de fechas"
                  startText="Desde"
                  endText="Hasta"
                  disableHighlightToday
                  minDate={new Date('2015-01-01')}
                  maxDate={new Date()}
                  value={fromToDate}
                  onChange={handleChangeFromToDate}
                  renderInput={(startProps, endProps) => (
                    <>
                      <TextField {...startProps} />
                      <Box sx={{ mx: 1 }}> - </Box>
                      <TextField {...endProps} />
                    </>
                  )}
                />
              </Box>
              {isDesktop && (
                <Button
                  variant="outlined"
                  size="small"
                  disabled={handleDisabledButton()}
                  onClick={handleClick}
                  sx={{ width: '70px', height: '30px', ml: 1 }}
                >
                  Aplicar
                </Button>
              )}
            </>
          ) : (
            <Button
              onClick={handleClean}
              variant="contained"
              size="small"
              sx={{
                ml: isDesktop && 2,
                mt: !isDesktop && 2,
              }}
            >
              {buttonTitle}
            </Button>
          )}
        </styles.FilterWrapper>
      </styles.Container>
      {type !== 'stats' && type !== 'dashboard' && (
        <Button
          onClick={handleClean}
          variant="text"
          size="small"
          sx={{
            textTransform: 'none',
            textDecoration: 'underline',
          }}
        >
          {buttonTitle}
        </Button>
      )}
    </LocalizationProvider>
  );
};

Filter.propTypes = {
  type: PropTypes.string,
  buttonTitle: PropTypes.string,
  onSelectDate: PropTypes.func,
  onSelectDateAdv: PropTypes.func,
  onClean: PropTypes.func,
};

export default Filter;
