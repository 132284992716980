export const formatData = (idOperacion, infoTestMode) => {
  const { images } = infoTestMode;
  const data = {
    dni_back: '',
    dni_front: '',
    id_op: idOperacion,
    selfies: [],
  };
  images
    .filter((image) => image.src !== '')
    .forEach(({ id, src, validation }) => {
      if (!id.includes('selfie')) {
        data[id] = src;
      } else {
        const { code } = validation;
        data.selfies.push({
          code: code,
          id_selfie: id === 'selfie' ? 1 : 2,
          image: src,
        });
      }
    });
  if (data.selfies.length == 0) {
    delete data.selfies;
  }
  return data;
};

export const formatValidationResult = (response, infoTestMode) => {
  const { images } = infoTestMode;
  const result = images.forEach((image) => {
    for (const elementResp in response) {
      infoTestMode.id_op = response['id_op'];
      infoTestMode.control_ok = response['control_ok'];
      if (elementResp === 'dni_back' || elementResp === 'dni_front') {
        if (elementResp === image.id) {
          image.img_quality = response[elementResp].img_quality;
          image.validation = {
            dni_side: response[elementResp].dni_side,
            suspicious: response[elementResp].suspicious,
          };
        }
      }
      if (image.id === 'dni_front') {
        image.validation = {
          ...image.validation,
          nombres: response['extracted_data'].nombres,
          apellidos: response['extracted_data'].apellidos,
          dni_numero: response['extracted_data'].dni_numero,
          ejemplar: response['extracted_data'].ejemplar,
          sexo: response['extracted_data'].sexo,
          fecha_nacimiento: response['extracted_data'].fecha_nacimiento,
          fecha_emision: response['extracted_data'].fecha_emision,
          fecha_vencimiento: response['extracted_data'].fecha_vencimiento,
          nacionalidad: response['extracted_data'].nacionalidad,
          numero_tramite: response['extracted_data'].numero_tramite,
          readable_barcode: response['readable_barcode'],
          match_ocr_barcode: response['match_ocr_barcode'],
          match_front_back: response['match_front_back'],
          ban_list_hit: response['ban_list_hit'],
        };
      }
      if (image.id === 'dni_back') {
        image.validation = {
          ...image.validation,
          dni_numero2: response['extracted_data'].dni_numero2,
          cuil: response['extracted_data'].cuil,
          domicilio: response['extracted_data'].domicilio,
          lugar_nacimiento: response['extracted_data'].lugar_nacimiento,
          fecha_ingreso: response['extracted_data'].fecha_ingreso,
          categoria_ingreso: response['extracted_data'].categoria_ingreso,
          expediente: response['extracted_data'].expediente,
          disposicion: response['extracted_data'].disposicion,
          fecha_radicacion: response['extracted_data'].fecha_radicacion,
          match_front_back_faces: response['match_front_back_faces'],
          codigo_dorso: response['extracted_data'].codigo_dorso,
        };
      }
      if (elementResp === 'selfies') {
        response[elementResp].forEach((element) => {
          if (element.id_selfie === 1 && image.id === 'selfie') {
            image.img_quality = element.img_quality;
            image.validation = {
              ...image.validation,
              proof: element.proof,
              match: element.match,
              suspicious: element.suspicious,
              // emotions: element.emotions,
              context_match: response['context_match'],
              // Agregado posible respuesta del renaper
              renaper_status: response.renaper_status,
              renaper_confidence: response.renaper_confidence,
              renaper_faceAuthenticationId: response.renaper_faceAuthenticationId,
            };
          }
          if (element.id_selfie === 2 && image.id === 'selfie_gesture') {
            image.img_quality = element.img_quality;
            image.validation = {
              ...image.validation,
              proof: element.proof,
              match: element.match,
              suspicious: element.suspicious,
              // emotions: element.emotions,
              context_match: response['context_match'],
            };
          }
        });
      }
    }
  });
  return result;
};
