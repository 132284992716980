import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import http from 'src/utils/api';
import styles from '../components/login/styles';
import Roles from '../routes/roles';

const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState({
    open: false,
    severity: '',
    message: '',
  });

  const handleDisabledButton = () => !email || !password || isLoading;

  const handleLogin = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('username', email);
      formData.append('password', password);
      const apiResponse = await http.post('accounts/login', formData);
      const { response } = apiResponse;
      if (response === 'Error') {
        const { error_message } = apiResponse;
        setIsOpen({
          open: true,
          severity: 'error',
          message: error_message,
        });
      } else {
        setIsOpen({
          open: true,
          severity: 'success',
          message: response,
        });
        const { token, admin, minions, email: user } = apiResponse;
        localStorage.setItem('token', token);
        localStorage.setItem('username', user);
        localStorage.setItem('country', 'ARG');
        localStorage.setItem('company', 'entreConsultas');
        let role;
        if (admin) {
          role = 'ADMIN_EC';
        } else if (minions) {
          role = 'MINIONS';
        } else {
          role = 'ADMIN_EMPRESA';
        }
        localStorage.setItem('role', role);
        navigate(Roles[role].initUrl);
      }
    } catch (error) {
      localStorage.clear();
      navigate('/login');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <styles.GridContainerStyled container>
        <styles.GridImageStyled item xs={12} sm={7}>
          <styles.BoxStyled>
            <styles.TitlesBoxStyled>
              <Typography
                color={theme.palette.background.default}
                variant="h1"
                sx={{
                  fontFamily: 'Livvic, sans-serif',
                  fontSize: '50px',
                  lineHeight: '48px',
                  fontWeight: '300',
                  letterSpacing: '-1.76471px',
                  mb: '40px',
                }}
              >
                Digital Onboarding
              </Typography>
              <Typography
                color={theme.palette.background.default}
                variant="h4"
                sx={{
                  fontFamily: 'Livvic, sans-serif',
                  fontSize: '25px',
                  fontWeight: '300',
                }}
              >
                Platform for validations of documents and identities DNI, credit cards, insurance
              </Typography>
            </styles.TitlesBoxStyled>
            <img alt="OBD_LOGO" width="128" src="/static/membrete_OBD.png" />
          </styles.BoxStyled>
        </styles.GridImageStyled>
        <styles.GridStyled item xs={12} sm={5}>
          <styles.FormContainer maxWidth="xs">
            <form>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <styles.FormTitlesStyled>
                  <Typography color="textPrimary" variant="h2" sx={{ mb: '10px' }}>
                    ¡Bienvenido!
                  </Typography>
                  <Typography color="textSecondary" gutterBottom variant="body2">
                    Inicia sesión con tu cuenta
                  </Typography>
                </styles.FormTitlesStyled>
                <styles.Logo alt="OBD_logo" width="128" src="/static/OBD_SIGN.png" />
              </Box>
              <TextField
                fullWidth
                label="Email"
                margin="normal"
                name="email"
                type="email"
                variant="outlined"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              <TextField
                fullWidth
                label="Password"
                margin="normal"
                name="password"
                type="password"
                variant="outlined"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column', mt: '40px' }}>
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  variant="contained"
                  type="submit"
                  disabled={handleDisabledButton()}
                  onClick={handleLogin}
                >
                  {isLoading ? <CircularProgress size={25} /> : 'Ingresar'}
                </Button>
                {/* <Button
                  size="medium"
                  variant="text"
                  sx={{
                    textTransform: 'lowercase',
                    textDecoration: 'underline',
                    mt: '10px'
                  }}
                  onClick={() => navigate('/forgotpassword')}
                >
                  Olvidé mi contraseña
                </Button> */}
              </Box>
            </form>
          </styles.FormContainer>
        </styles.GridStyled>
      </styles.GridContainerStyled>
      {isOpen.open && (
        <Snackbar
          open={isOpen.open}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          onClose={handleClose}
        >
          <Alert
            severity={isOpen.severity}
            variant="filled"
            onClose={handleClose}
            sx={{ width: '100%' }}
          >
            {isOpen.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default Login;
