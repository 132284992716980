import {
  Paper,
  Box,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Button,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { DateRangePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import esLocale from 'date-fns/locale/es';
import ExportData from 'src/components/transactions/ExportData';
import { Search } from '@mui/icons-material';
import useTransactions from 'src/hooks/useTransactions';

const ENDPOINTS = [
  {
    label: 'Todos los endpoints',
    value: 0,
  },
  {
    label: 'Endpoint 1',
    value: 1,
  },
  {
    label: 'Endpoint 2',
    value: 2,
  },
  {
    label: 'Endpoint 3',
    value: 3,
  },
  {
    label: 'Endpoint 4',
    value: 4,
  },
];

const columns = [
  { field: 'id_operacion', headerName: 'ID Operación', width: 150, sorteable: false },
  { field: 'tipo', headerName: 'Tipo', width: 130, sorteable: false },
  { field: 'endpoint', headerName: 'Endpoint', width: 100, sorteable: false },
  {
    field: 'UTCDate',
    headerName: 'Fecha',
    type: 'dateTime',
    width: 170,
    sorteable: false,
  },
  {
    field: 'cantidad_procesados',
    type: 'number',
    headerName: 'Cantidad',
    width: 100,
    sorteable: true,
  },
  {
    field: 'empresa',
    headerName: 'Empresa',
    width: 160,
    sorteable: false,
  },
];
export default function Transactions() {
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [fromToDate, setFromToDate] = useState([null, null]);
  const [selectedEndpoint, setSelectedEndpoint] = useState(0);
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [searchText, setSearchText] = useState('');
  const role = localStorage.getItem('role');
  const [transactions, companyList, isLoading] = useTransactions();

  const byDate = ({ UTCDate }) => {
    if (!fromToDate[0] || !fromToDate[1]) return true;
    const date = new Date(UTCDate);
    date.setHours(0, 0, 0);
    return date.getTime() >= fromToDate[0] && date.getTime() <= fromToDate[1];
  };

  const byEndpoint = ({ endpoint }) => {
    if (!selectedEndpoint) return true;
    return endpoint === selectedEndpoint;
  };

  const byCompany = ({ empresa }) => {
    if (!selectedCompany) return true;
    const companyName = companyList.filter((company) => company.value === selectedCompany)[0].label;
    return empresa === companyName;
  };

  const bySearchText = ({ id_operacion }) => {
    if (searchText === '') return true;
    return id_operacion.toString().indexOf(searchText) >= 0;
  };

  useEffect(() => {
    setFilteredTransactions(transactions);
  }, [companyList]);

  useEffect(() => {
    setIsLoadingFilter(true);
    setFilteredTransactions([]);
    const timeout = setTimeout(() => {
      const _filteredTransactions = transactions
        .filter(byDate)
        .filter(byEndpoint)
        .filter(byCompany)
        .filter(bySearchText);
      setFilteredTransactions(_filteredTransactions);
      setIsLoadingFilter(false);
    }, 300);
    return () => clearTimeout(timeout);
  }, [transactions, fromToDate, selectedEndpoint, selectedCompany, searchText]);

  const handleChangeFromToDate = (newValue) => {
    setFromToDate([newValue[0], null]);
  };

  const handleOnAccept = (newValue) => {
    setFromToDate(newValue);
  };

  const handleClean = () => {
    setFromToDate([null, null]);
    setSelectedCompany(0);
    setSelectedEndpoint(0);
    setSearchText('');
  };

  const parseExcelData = (data) => {
    return data.map((_data) => {
      return [
        _data.id_operacion,
        _data.tipo,
        _data.endpoint,
        _data.fecha,
        _data.cantidad_procesados,
        _data.empresa,
      ];
    });
  };

  return (
    <div>
      <Box
        component={Paper}
        sx={{
          p: 2,
          maxWidth: {
            xs: '100%',
            md: 900,
          },
        }}
      >
        <Typography color="textPrimary" variant="body1" sx={{ fontSize: 14, mb: 2 }}>
          Filtros
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
          <DateRangePicker
            disabled={isLoading || isLoadingFilter}
            toolbarTitle="Seleccionar intervalo de fechas"
            startText="Fecha desde"
            endText="Fecha hasta"
            disableHighlightToday
            maxDate={new Date()}
            value={fromToDate}
            onChange={handleChangeFromToDate}
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} />
                <Box sx={{ mx: 1 }}> - </Box>
                <TextField {...endProps} />
              </>
            )}
            onAccept={handleOnAccept}
          />
        </LocalizationProvider>
        <Box
          sx={{
            my: 2,
          }}
        >
          {role === 'ADMIN_EC' && (
            <FormControl style={{ marginRight: 25 }}>
              <Select
                disabled={isLoading || isLoadingFilter}
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(parseInt(e.target.value))}
              >
                {companyList.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl>
            <Select
              disabled={isLoading || isLoadingFilter}
              value={selectedEndpoint}
              onChange={(e) => setSelectedEndpoint(parseInt(e.target.value))}
            >
              {ENDPOINTS.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0' }}>
          <FormControl sx={{ mr: 2 }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Busqueda</InputLabel>
            <OutlinedInput
              disabled={isLoading}
              placeholder="Ingrese ID Operación"
              id="outlined-adornment-password"
              type="text"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <Button
            onClick={handleClean}
            variant="text"
            size="small"
            style={{ margin: 0 }}
            sx={{
              textTransform: 'none',
              textDecoration: 'underline',
            }}
          >
            Mostrar todas las transacciones
          </Button>
          {/* <Button
            onClick={updateTransactions}
            variant="text"
            size="small"
            style={{ margin: 0 }}
            sx={{
              textTransform: 'none',
              textDecoration: 'underline',
            }}
          >
            Actualizar listado
          </Button> */}
        </div>
        <DataGrid
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          autoHeight
          rows={filteredTransactions}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          density="compact"
          loading={isLoading || isLoadingFilter}
          disableSelectionOnClick
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
          <ExportData data={parseExcelData(filteredTransactions)} user={role} />
        </div>
      </Box>
    </div>
  );
}
