import PropTypes from 'prop-types';
import { Box, SwipeableDrawer } from '@mui/material';
import DrawerHeader from './DrawerHeader';
import Content from './Content';

export default function Drawer({ open, control, content, idOp, dni, endpoint }) {
  return (
    <SwipeableDrawer onOpen={control()} onClose={control()} anchor="right" open={open}>
      <Box
        minWidth={400}
        sx={{ scrollbarGutter: 'stable', position: 'relative' }}
        role="presentation"
      >
        <DrawerHeader idOp={idOp} dni={dni} endpoint={endpoint} closeDrawer={control} />
        <Content endpointData={content} />
      </Box>
    </SwipeableDrawer>
  );
}

Drawer.propTypes = {
  content: PropTypes.object,
  control: PropTypes.func,
  dni: PropTypes.string,
  endpoint: PropTypes.number,
  idOp: PropTypes.number,
  open: PropTypes.bool,
};

Drawer.defaultProps = {
  open: false,
  control: () => {},
  content: {},
  idOp: 0,
  dni: '',
  endpoint: 0,
};
