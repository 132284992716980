import PropTypes from 'prop-types';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from '@mui/material';
import { green, red } from '@mui/material/colors';
import CheckContained from '@mui/icons-material/CheckCircle';
import CloseContained from '@mui/icons-material/Cancel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { theme } from 'src/theme';
import Drawer from './Drawer';
import DrawerFotos from './DrawerFotos';
import styles from './styles';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row({ id_op, dni, data, quantity, date }) {
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDrawerFotos, setOpenDrawerFotos] = useState(false);
  const [drawerContent, setDrawerContent] = useState({});
  const [drawerFotosContent, setDrawerFotosContent] = useState(0);
  const [currentEndpoint, setEndpoint] = useState(0);
  const classes = useRowStyles();

  const toggleDrawer =
    (details = {}, endpoint = 0) =>
    (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      setEndpoint(endpoint);
      setDrawerContent(details);
      setOpenDrawer((prev) => !prev);
    };

    const toggleDrawerFotos =
    (idConjuntoImagenes = 0, endpoint = 0) =>
    (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      setEndpoint(endpoint);
      setDrawerFotosContent(idConjuntoImagenes);
      
      setOpenDrawerFotos((prev) => !prev);
    };

  // TODO: hay que ver excatamente que endpoints son los correctos
  // En este momento, le harcodeo el nro 3, pero hay que verlo
  var lastEndPoint = 3;
  var is_transaction_ok = data && data.filter((el) => {return (el.endpoint == lastEndPoint) && el.control_ok}).length;

  return (
    <>
      <TableRow className={classes.root}>
        <styles.ExpandCell>
          <styles.ExpandIcon aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </styles.ExpandIcon>
          <styles.ContentCell>{id_op}</styles.ContentCell>
        </styles.ExpandCell>
        <styles.Cell>
          <styles.ContentCell>{date.toLocaleDateString() || <em>sin datos</em>}</styles.ContentCell>
        </styles.Cell>
        <styles.Cell>
          <styles.ContentCell>{quantity}</styles.ContentCell>
        </styles.Cell>
        <styles.Cell>
          <styles.ContentCell>{dni}</styles.ContentCell>
        </styles.Cell>
        <styles.Cell>
          <styles.ContentCell>
          {
          is_transaction_ok ? 
            (
              <CheckContained style={{ color: green[500] }} />
            ) : (
              <CloseContained style={{ color: red[500] }} />
            )}
            </styles.ContentCell>
        </styles.Cell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{
                backgroundColor: theme.palette.background.default,
                padding: '8px 4px',
              }}
            >
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Control OK</TableCell>
                    <TableCell align="center">Endpoint</TableCell>
                    <TableCell align="center">&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data.map(({ id = '', endpoint = '', control_ok = false, details = {}}) => (
                      <TableRow key={id}>
                        <TableCell align="center">
                          {control_ok ? (
                            <CheckContained style={{ color: green[500] }} />
                          ) : (
                            <CloseContained style={{ color: red[500] }} />
                          )}
                        </TableCell>
                        <TableCell align="center">{endpoint}</TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={toggleDrawer(details, endpoint)}
                            variant="outlined"
                            size="small"
                          >
                            DETALLES
                          </Button>
                        </TableCell>
                        
                        {details.id_conjunto_imagenes && (<TableCell align="center">
                          <Button
                            onClick={toggleDrawerFotos(details.id_conjunto_imagenes,endpoint)}
                            variant="outlined"
                            size="small"
                          >
                            IMÁGENES
                          </Button>
                        </TableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <Drawer
                open={openDrawer}
                control={toggleDrawer}
                idOp={id_op}
                dni={dni}
                content={drawerContent}
                endpoint={currentEndpoint}
              />
              
              <DrawerFotos
                open={openDrawerFotos}
                control={toggleDrawerFotos}
                idOp={id_op}
                dni={dni}
                idConjuntoImagenes={drawerFotosContent}
                endpoint={currentEndpoint}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  id_op: PropTypes.number,
  dni: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  quantity: PropTypes.number,
  date: PropTypes.instanceOf(Date),
};

Row.defaultProps = {
  id_op: 0,
  dni: '',
  data: [],
  quantity: 0,
  date: '',
};

export default Row;
