export default [
  {
    id: 'cedula_front',
    properties: {
      cedula_side: 'Cédula Side',
      nombres: 'Nombres',
      apellidos: 'Apellidos',
      cedula_numero: 'Cédula Número',
      sexo: 'Sexo',
      rh: 'RH',
      estatura: 'Estatura',
      fecha_nacimiento: 'Fecha de Nac.',
      lugar_nacimiento: 'Lugar Nacimiento',
      fecha_lugar_expedicion: 'Fecha y Lugar de expedición',
      fecha_expedicion: 'Fecha de expedición',
      fecha_vencimiento: 'Fecha de Vto',
      nacionalidad: 'Nacionalidad',
    },
  },
  {
    id: 'cedula_back',
    properties: {
      cedula_side: 'Cédula Side',
      cedula_numero2: 'Cédula Número',
      fecha_nacimiento: 'Fecha de Nac.',
      lugar_nacimiento: 'Lugar Nacimiento',
      sexo: 'Sexo',
      rh: 'RH',
      estatura: 'Estatura',
      fecha_lugar_expedicion: 'Fecha y Lugar de expedición',
      readable_barcode: 'Barcode Legible',
      readable_qr: 'QR Legible',
      match_ocr_barcode: 'Match OCR-Barcode',
      match_front_back: 'Match de datos Frente-Dorso',
      match_front_back_faces: 'Match de rostros Frente-dorso',
      codigo_dorso: 'Código Dorso',
    },
  },
  {
    id: 'selfie',
    properties: {
      code: 'Código',
      proof: 'Prueba de vida',
      match: 'Match',
      suspicious: 'Sospechosa',
      emotions: 'Emociones',
      context_match: 'Contexto',
    },
  },
  {
    id: 'selfie_gesture',
    properties: {
      code: 'Código',
      proof: 'Prueba de vida',
      match: 'Match',
      suspicious: 'Sospechosa',
      emotions: 'Emociones',
      context_match: 'Contexto',
    },
  },
];
