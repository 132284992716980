import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { initialStateDni } from 'src/components/testMode/initialStateDni';
import { initialStateCedula } from 'src/components/testMode/initialStateCedula';
import { initialStatePolicy } from 'src/components/testModePolicy/initialStatePolicy';

const Context = createContext();

export const TestModeProvider = ({ children }) => {
  const [country, setCountry] = useState('');
  const [infoTestMode, setInfoTestMode] = useState('');
  const [infoInsurancePolicy, setInfoInsurancePolicy] = useState(initialStatePolicy);

  useEffect(() => {
    const getCountry = () => {
      const country = localStorage.getItem('country');
      setCountry(country);
    };
    getCountry();
  }, []);

  useEffect(() => {
    if (country === 'ARG') {
      setInfoTestMode(initialStateDni);
    } else if (country === 'COL') {
      setInfoTestMode(initialStateCedula);
    }
  }, [country]);

  return (
    <>
      {infoTestMode && (
        <Context.Provider
          value={{ infoTestMode, setInfoTestMode, infoInsurancePolicy, setInfoInsurancePolicy }}
        >
          {children}
        </Context.Provider>
      )}
    </>
  );
};

TestModeProvider.propTypes = {
  children: PropTypes.node,
};

export const TestModeContext = () => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('use TestModeContext inside TestModeProvider');
  }
  return context;
};
