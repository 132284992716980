import { useState, useEffect } from 'react';
import { Box, Container, Grid } from '@mui/material';
import TotalByDay from 'src/components/dashboard/TotalByDay';
import Movements from 'src/components/dashboard/Movements';
import TotalByClient from 'src/components/dashboard/TotalByClient';
import CompanyMovements from 'src/components/dashboard/CompanyMovements';
import Incomes from 'src/components/dashboard/Incomes';
import { formatTotalByDay, formatTotalByCompany } from 'src/utils/chart';
import Filter from 'src/components/reusableComponents/Filter';
import http from 'src/utils/api';

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [transactionsByDay, setTransactionsByDay] = useState([]);
  const [transactionsByCompany, setTransactionsByCompany] = useState([]);
  const [date, setDate] = useState(new Date());
  const [companies, setCompanies] = useState([]);
  const todayDate = new Date().getDate();
  const role = localStorage.getItem('role');

  useEffect(() => {
    const getTransactions = async () => {
      const headerConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const data = {
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      };
      try {
        setIsLoading(true);
        if (role === 'ADMIN_EC') {
          const responseApi = await http.post('api/dosearch/', data, headerConfig);
          setCompanies(formatTotalByCompany(responseApi).map((company) => company.name));
          setTransactionsByCompany(formatTotalByCompany(responseApi));
        }
        const response = await http.post('api/osearch/', data, headerConfig);
        setTransactionsByDay(formatTotalByDay(response));
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    getTransactions();
  }, [date]);

  const getFilterTransactions = (newDate) => {
    setDate(newDate);
  };

  const handleCleanFilter = () => {
    getFilterTransactions(new Date());
  };

  if (role === 'MINIONS') return null;

  return (
    <>
      <Filter
        type="dashboard"
        buttonTitle="Limpiar"
        onSelectDate={getFilterTransactions}
        onClean={handleCleanFilter}
      />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          width: '100%',
          pb: 2,
        }}
      >
        <Container disableGutters maxWidth={false}>
          <Grid
            sx={{ width: '100%' }}
            container
            spacing={{ xs: 2, md: 3 }}
            justifyContent="center"
            alignItems="center"
          >
            {role === 'ADMIN_EC' && (
              <>
                <Grid item xl={6} xs={12}>
                  <TotalByDay data={transactionsByDay} companies={companies} loading={isLoading} />
                </Grid>
                <Grid item xl={6} xs={12}>
                  <TotalByClient
                    data={transactionsByCompany}
                    companies={companies}
                    loading={isLoading}
                  />
                </Grid>
                <Grid item lg={6} md={12} xl={6} xs={12}>
                  <Movements data={transactionsByCompany} loading={isLoading} />
                </Grid>
                <Grid item lg={6} md={12} xl={6} xs={12}>
                  <Incomes data={transactionsByCompany} loading={isLoading} />
                </Grid>
              </>
            )}
            {role === 'ADMIN_EMPRESA' && (
              <>
                <Grid item xs>
                  <CompanyMovements data={transactionsByDay} date={todayDate} loading={isLoading} />
                </Grid>
                <Grid item xs={12}>
                  <TotalByDay data={transactionsByDay} loading={isLoading} />
                </Grid>
              </>
            )}
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default Dashboard;
