import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import NotFound from 'src/pages/NotFound';
import { getAllowedRoutes } from 'src/utils/auth';
import privateRoutes from 'src/routes/privateRoutes';
import publicRoutes from 'src/routes/publicRoutes';

const routes = (isLoggedIn) => [
  {
    path: '/app',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
    children: isLoggedIn && getAllowedRoutes(privateRoutes),
  },
  {
    path: '/',
    element: !isLoggedIn ? <MainLayout /> : <Navigate to="/app" />,
    children: publicRoutes,
  },
  {
    path: '/404',
    element: <NotFound />,
  },
  {
    path: '*',
    element: <Navigate to="/404" />,
  },
];

export default routes;
