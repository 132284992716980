const roles = {
  ADMIN_EC: {
    value: 'ADMIN_EC',
    initUrl: '/app/dashboard',
  },
  ADMIN_EMPRESA: {
    value: 'ADMIN_EMPRESA',
    initUrl: '/app/dashboard',
  },
  MINIONS: {
    value: 'MINIONS',
    initUrl: '/app/transactions',
  },
};

export default roles;
