export const initialStateDni = {
  id_op: '',
  control_ok: '',
  images: [
    {
      id: 'dni_front',
      title: 'DNI FRENTE',
      src: '',
      validation: {},
      img_quality: '',
    },
    {
      id: 'dni_back',
      title: 'DNI DORSO',
      src: '',
      validation: {},
      img_quality: '',
    },
    {
      id: 'selfie',
      title: 'SELFIE',
      src: '',
      validation: {},
      img_quality: '',
    },
    {
      id: 'selfie_gesture',
      title: 'SELFIE + GESTO',
      src: '',
      validation: {},
      img_quality: '',
    },
  ],
};
