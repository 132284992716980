import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import { controlOk } from './data';

const TrafficLightIconStyled = styled(Alert)`
  border: none;
  padding: 0px 8px;

  .MuiSvgIcon-root {
    width: 30px;
    height: 30px;
  }

  @media (min-width: 500px) {
    display: none;
  }
`;

const TrafficLightIcon = ({ value }) => (
  <TrafficLightIconStyled variant="outlined" severity={controlOk[value]} />
);

TrafficLightIcon.propTypes = {
  value: PropTypes.bool.isRequired,
};

export default TrafficLightIcon;
