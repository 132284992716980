import {
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  colors,
  Divider,
  ListItemText,
  Slider,
  Snackbar,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState, useEffect } from 'react';
// import Autocomplete from 'src/components/reusableComponents/Autocomplete/Autocomplete';
import initialState from 'src/components/settings/initialState';
import http from 'src/utils/api';
import { isInvalidUrl } from 'src/utils/helper';
import {
  getNewConfig,
  getSettingChanges,
  formatSetting,
  formatAdvancedSetting,
  compareSetting,
} from 'src/components/settings/helper';
import styles from '../components/settings/styles';

const Settings = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingResp, setIsLoadingResp] = useState(false);
  // const [company, setCompany] = useState('Entre Consultas');
  const [setting, setSetting] = useState(initialState);
  const [clientConfig, setClientConfig] = useState('');
  const [advancedSetting, setAdvancedSetting] = useState('');
  const [isOpen, setIsOpen] = useState({
    open: false,
    severity: '',
    message: '',
  });

  const theme = useTheme();
  const tabletScreen = useMediaQuery(theme.breakpoints.down('md'));
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // mock
  // const companies = ['Entre Consultas', 'TAP', 'Directo', 'TEST'];

  const getIsExpanded = () => {
    setting.config
      .filter((element) => element.name === 'validationControl')
      .forEach(({ variables }) => {
        variables.forEach(({ isActive }) => {
          if (isActive) {
            setIsExpanded(true);
          } else {
            setIsExpanded(false);
          }
        });
      });
  };

  useEffect(() => {
    const getSetting = async () => {
      try {
        setIsLoading(true);
        const response = await http.get('api/clientconfig/');
        const responseApi = await http.get('api/empresaconfig/');
        const clientSettings = response[0];
        const companySettings = responseApi[0];
        const newSetting = getNewConfig(setting, clientSettings, companySettings);
        setClientConfig(clientSettings);
        setAdvancedSetting(companySettings);
        setSetting({
          id_company: clientSettings.id_empresa,
          id_clientSettings: clientSettings.id,
          id_companySettings: companySettings.id,
          config: newSetting,
        });
        getIsExpanded();
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    getSetting();
  }, []);

  const handleChange = (id, event) => {
    const { config } = setting;
    const changedSetting = getSettingChanges(config, id, event);
    setSetting({ ...setting, config: changedSetting });
    getIsExpanded();
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const saveChanges = async () => {
    const headerConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      setIsLoadingResp(true);
      const config = formatSetting(setting);
      const isChange = compareSetting(clientConfig, config);
      if (isChange) {
        await http.put(
          `api/clientconfig/${setting.id_clientSettings}/update/`,
          config,
          headerConfig
        );
        setIsOpen({
          open: true,
          severity: 'success',
          message: 'Los cambios fueron realizados exitosamente',
        });
      } else {
        setIsOpen({
          open: true,
          severity: 'warning',
          message: 'No se realizó ningún cambio.',
        });
      }
      setClientConfig(config);
    } catch (error) {
      setIsOpen({
        open: true,
        severity: 'error',
        message: 'Ha ocurrido un inconveniente.',
      });
      const unchangedSettings = getNewConfig(setting, clientConfig);
      setSetting({ ...setting, config: unchangedSettings });
    } finally {
      setIsLoadingResp(false);
    }
  };

  const saveChangesAdvanced = async () => {
    const headerConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const advancedSettingsVariables = Object.assign(
      ...setting.config
        .filter(({ name }) => name === 'advancedSettings')
        .map(({ variables }) => variables)
    );
    const urlClientRedirect = advancedSettingsVariables
      .filter(({ id }) => id === 'client_redirect')
      .map(({ value }) => value);
    const urlWebhook = advancedSettingsVariables
      .filter(({ id }) => id === 'webhook_url')
      .map(({ value }) => value);
    try {
      setIsLoadingResp(true);
      if (isInvalidUrl(...urlClientRedirect)) {
        setIsOpen({
          open: true,
          severity: 'warning',
          message: 'URL Client Redirection No Válida',
        });
      } else if (isInvalidUrl(...urlWebhook)) {
        setIsOpen({
          open: true,
          severity: 'warning',
          message: 'URL Webhook No Válida',
        });
      } else {
        const configData = formatAdvancedSetting(setting, advancedSetting);
        const isChangeAdvanced = compareSetting(advancedSetting, configData);
        if (isChangeAdvanced) {
          await http.put(
            `api/empresaconfig/${setting.id_companySettings}/update/`,
            configData,
            headerConfig
          );
          setIsOpen({
            open: true,
            severity: 'success',
            message: 'Los cambios fueron realizados exitosamente',
          });
        } else {
          setIsOpen({
            open: true,
            severity: 'warning',
            message: 'No se realizó ningún cambio.',
          });
        }
        setAdvancedSetting(configData);
      }
    } catch (error) {
      setIsOpen({
        open: true,
        severity: 'error',
        message: 'Ha ocurrido un inconveniente.',
      });
      const unchangedSettings = getNewConfig(setting, null, advancedSetting);
      setSetting({ ...setting, config: unchangedSettings });
    } finally {
      setIsLoadingResp(false);
    }
  };

  return (
    <>
      <styles.GeneralContainer tablet={tabletScreen}>
        {/* {isAdmin && (
          <Autocomplete
            options={companies}
            size="small"
            label="Empresa"
            variant="outlined"
            value={company}
            setValue={setCompany}
            style={{ marginBottom: '20px', width: '200px' }}
          />
        )} */}
        {setting.config.map(({ name, title, variables, subVariables }) => (
          <Box key={name}>
            {name !== 'advancedSettings' ? (
              <styles.ListStyled
                expandedList={name === 'validationControl'}
                isExpanded={isExpanded}
              >
                <Typography color={colors.grey[600]} variant="h4">
                  {title}
                </Typography>
                <Divider sx={{ width: '100%', my: '20px' }} />
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <CircularProgress size={25} />
                  </Box>
                ) : (
                  variables.map(({ id, label, text, isActive }) => (
                    <styles.ListItemStyled
                      key={id}
                      itemStyled={name !== 'general'}
                      mobile={mobileScreen}
                    >
                      <styles.TextContainer>
                        <ListItemText primary={label} />
                        <Tooltip title={text}>
                          <HelpOutlineIcon
                            color="action"
                            sx={{ fontSize: '15px', marginLeft: '10px' }}
                          />
                        </Tooltip>
                      </styles.TextContainer>
                      {name === 'general' ? (
                        <Checkbox
                          edge="end"
                          checked={isActive}
                          onChange={(e) => handleChange(id, e)}
                        />
                      ) : (
                        <Switch
                          edge="end"
                          checked={isActive}
                          onChange={(e) => handleChange(id, e)}
                        />
                      )}
                    </styles.ListItemStyled>
                  ))
                )}
              </styles.ListStyled>
            ) : (
              <styles.AccordionStyled>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography color={colors.grey[600]} variant="h4">
                    {title}
                  </Typography>
                </AccordionSummary>
                <Divider sx={{ mb: '20px', mx: '20px' }} />
                <AccordionDetails>
                  {isLoading ? (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                      }}
                    >
                      <CircularProgress size={25} />
                    </Box>
                  ) : (
                    variables.map(({ id, label, text, value }) => (
                      <styles.ListItemStyled
                        key={id}
                        itemStyled
                        mobileConfig={name === 'advancedSettings' && mobileScreen}
                        listItemStyled
                      >
                        <styles.TextContainer>
                          <ListItemText primary={label} />
                          <Tooltip title={text}>
                            <HelpOutlineIcon
                              color="action"
                              sx={{ fontSize: '15px', marginLeft: '10px' }}
                            />
                          </Tooltip>
                        </styles.TextContainer>
                        {id !== 'client_redirect' && id !== 'webhook_url' ? (
                          <styles.SliderContainer mobile={mobileScreen}>
                            <Typography color={colors.grey[600]} variant="h6">
                              estricto
                            </Typography>
                            <Slider
                              name={label}
                              value={value}
                              valueLabelDisplay="auto"
                              step={0.01}
                              min={id === 'faceTolerance' ? 0.5 : 0.4}
                              max={id === 'faceTolerance' ? 0.8 : 0.9}
                              onChange={(e) => handleChange(id, e)}
                            />
                            <Typography color={colors.grey[600]} variant="h6">
                              permisivo
                            </Typography>
                          </styles.SliderContainer>
                        ) : (
                          <styles.TextFieldStyled
                            mobile={`${mobileScreen}`}
                            id={id !== 'client_redirect' ? 'webhook_url' : 'client_redirect'}
                            name={id !== 'client_redirect' ? 'webhook_url' : 'client_redirect'}
                            type="url"
                            variant="outlined"
                            placeholder="ingrese una URL"
                            value={value === null ? '' : value}
                            onChange={(e) => handleChange(id, e)}
                          />
                        )}
                      </styles.ListItemStyled>
                    ))
                  )}
                  {!isLoading && (
                    <styles.ButtonContainer ButtonStyled>
                      <Button
                        variant="contained"
                        size="small"
                        disabled={isLoadingResp}
                        onClick={saveChangesAdvanced}
                        sx={{ width: '80px', height: '30px' }}
                      >
                        {isLoadingResp ? <CircularProgress size={25} /> : 'Aplicar'}
                      </Button>
                    </styles.ButtonContainer>
                  )}
                </AccordionDetails>
              </styles.AccordionStyled>
            )}
            {subVariables && (
              <styles.DivStyled isExpanded={isExpanded}>
                <styles.SubListStyled>
                  {subVariables.map(({ id, label, text, isActive }) => (
                    <styles.ListItemStyled key={id} mobile={mobileScreen}>
                      <styles.TextContainer>
                        <ListItemText primary={label} />
                        <Tooltip title={text}>
                          <HelpOutlineIcon
                            color="action"
                            sx={{ fontSize: '15px', marginLeft: '10px' }}
                          />
                        </Tooltip>
                      </styles.TextContainer>
                      <Checkbox
                        edge="end"
                        onChange={(e) => handleChange(id, e)}
                        checked={isActive}
                      />
                    </styles.ListItemStyled>
                  ))}
                </styles.SubListStyled>
              </styles.DivStyled>
            )}
            {name !== 'advancedSettings' && (
              <styles.ButtonContainer>
                <Button
                  variant="contained"
                  size="small"
                  disabled={isLoadingResp}
                  onClick={saveChanges}
                  sx={{ width: '80px', height: '30px' }}
                >
                  {isLoadingResp ? <CircularProgress size={20} /> : 'Aplicar'}
                </Button>
              </styles.ButtonContainer>
            )}
          </Box>
        ))}
      </styles.GeneralContainer>
      {isOpen.open && (
        <Snackbar
          open={isOpen.open}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          onClose={handleClose}
        >
          <Alert
            severity={isOpen.severity}
            variant="filled"
            onClose={handleClose}
            sx={{ width: '100%' }}
          >
            {isOpen.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default Settings;
