import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { initialStateTemplate } from 'src/components/templatesSettings/initialStateTemplate';

const Context = createContext();

export const TemplatesProvider = ({ children }) => {
  const [template, setTemplate] = useState(initialStateTemplate);

  return <Context.Provider value={{ template, setTemplate }}>{children}</Context.Provider>;
};

TemplatesProvider.propTypes = {
  children: PropTypes.node,
};

export const TemplatesContext = () => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('use TemplatesContext inside TemplatesProvider');
  }
  return context;
};
