import PropTypes from 'prop-types';
import { createContext, useContext, useState } from 'react';

const UserContext = createContext();
function UserProvider({ children }) {
  const [userType, setUserType] = useState('MINIONS');

  const state = { userType, setUserType };

  return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
}

/**
 * @name useUser - user context custom hook
 * @example const {userType, setUserType} = useUser();
 * @returns context object with the current value (userType) and
 * a function for changing it (setUserType)
 */
function useUser() {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUser have to be used inside a provider');
  }
  return context;
}

UserProvider.propTypes = {
  children: PropTypes.any,
};

export { UserProvider, useUser };
