import { useEffect, useState } from 'react';
// import { db } from 'src/config/db';
import http from 'src/utils/api';
import { parseDate } from 'src/utils/helper';
// import { data } from 'src/__mocks__/transactions';

const useTransactions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [companyList, setCompanyList] = useState([{ label: 'Todas las empresas', value: 0 }]);

  const buildCompaniesSelector = (transactions) => {
    const companies = [{ label: 'Todas las empresas', value: 0 }];
    let index = 1;
    transactions.forEach((transaction) => {
      if (companies.findIndex((company) => company.label === transaction.empresa) === -1) {
        companies.push({
          label: transaction.empresa,
          value: index,
        });
        index++;
      }
    });
    return companies;
  };

  // const updateTransactions = async () => {
  //   console.log('actualizar');
  //   await db.transactions.clear();
  //   console.log('done');
  //   fetchData();
  // };

  // const saveIntoDB = async (transactions) => {
  //   await db.transactions.clear();
  //   try {
  //     const _transactions = transactions.map((transaction) => ({
  //       id: transaction.id,
  //       id_empresa: transaction.id_empresa,
  //       id_operacion: transaction.id_operacion,
  //       empresa: transaction.empresa,
  //       tipo: transaction.tipo,
  //       endpoint: transaction.endpoint,
  //       cantidad_procesados: transaction.cantidad_procesados,
  //       fecha: transaction.fecha,
  //       UTCDate: transaction.UTCDate,
  //     }));
  //     db.transactions.bulkAdd(_transactions);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getAPIData = async () => {
    try {
      setIsLoading(true);
      const response = await http.get('api/transactions/');
      // const response = data;
      const transactions = response
        .sort((a, b) => b.id - a.id)
        .map((transaction) => ({
          ...transaction,
          UTCDate: parseDate(transaction.fecha),
        }));
      const companies = buildCompaniesSelector(transactions);
      setCompanyList(companies);
      setTransactions(transactions);
      // saveIntoDB(transactions);
    } catch (error) {
      setTransactions([]);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // const fetchData = async () => {
  //   try {
  //     setIsLoading(true);
  //     setTransactions([]);
  //     setCompanyList([{ label: 'Todas las empresas', value: 0 }]);
  //     const transactions = await db.transactions.toArray();
  //     console.log('transactions array db', transactions);
  //     if (transactions.length) {
  //       console.log('from db');
  //       const companies = buildCompaniesSelector(transactions);
  //       setCompanyList(companies);
  //       setTransactions(transactions);
  //     } else {
  //       console.log('from api');
  //       await getAPIData();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  useEffect(() => {
    // fetchData();
    getAPIData();
  }, []);

  return [transactions, companyList, isLoading];
};

export default useTransactions;
