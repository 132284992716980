import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Snackbar,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import ArrowRight from '@mui/icons-material/ArrowRight';
import styled from '@emotion/styled/macro';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { TemplatesContext } from 'src/context/templatesContext';
import { typeDocuments } from 'src/components/templateAdd/data';
import ImageCard from 'src/components/reusableComponents/ImageCard';

const GridStyled = styled(Grid)`
  @media (max-width: 900px) {
    padding: 0 80px;
  }
  @media (max-width: 500px) {
    padding: 0px;
  }
`;

const BoxStyled = styled(Box)`
  min-width: 180px;
  max-width: 180px;
`;

const AddTemplate = () => {
  const { template, setTemplate } = TemplatesContext();
  const { file } = template;
  const [documentType, setDocumentType] = useState('insurance_policy');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleResult = () => {
    if (file.src === '') {
      setIsOpen({
        open: true,
        severity: 'error',
        message: 'Debe ingresar un archivo para poder continuar',
      });
      return;
    }
    try {
      setIsLoading(true);
      navigate('/app/editTemplate');
    } catch (error) {
      setIsOpen({
        open: true,
        severity: 'error',
        message: 'Ha ocurrido un inconveniente.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
        }}
      >
        <Container maxWidth={false} disableGutters>
          <Box>
            <GridStyled container spacing={3}>
              <Grid item xs={12}>
                <BoxStyled>
                  <FormControl fullWidth disabled>
                    <Select value={documentType} onChange={(e) => setDocumentType(e.target.value)}>
                      {typeDocuments.map(({ type, name }) => (
                        <MenuItem key={type} value={type}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </BoxStyled>
              </Grid>
              <Grid item md={5} xs={12}>
                <Box>
                  <ImageCard
                    imageData={file}
                    accept={'image/*,.pdf'}
                    contextData={template}
                    setContextData={setTemplate}
                    isPolicyValidation={false}
                    isTemplate
                  />
                  <Box
                    mt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button endIcon={<ArrowRight />} variant="contained" onClick={handleResult}>
                      siguiente
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </GridStyled>
          </Box>
        </Container>
      </Box>
      <Dialog open={isLoading}>
        <DialogTitle
          sx={{ display: 'flex', justifyContent: 'center', px: 15, pt: 6, fontSize: '30px' }}
        >
          Analizando Documento
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', justifyContent: 'center', px: 15, pb: 6 }}>
          <CircularProgress size={25} />
        </DialogContent>
      </Dialog>
      {isOpen.open && (
        <Snackbar
          open={isOpen.open}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          onClose={handleClose}
        >
          <Alert
            severity={isOpen.severity}
            variant="filled"
            onClose={handleClose}
            sx={{ width: '100%' }}
          >
            {isOpen.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default AddTemplate;
