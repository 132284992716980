export default {
  entreConsultas: {
    color: '#5664d2',
  },
  tap: {
    color: '#f4b10d',
  },
  directo: {
    color: '#881ad5',
  },
  visir: {
    color: '#a8d70b',
  },
};
