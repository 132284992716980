import { useEffect, useState } from 'react';
import { isWithinInterval } from 'date-fns';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
} from '@mui/material';
import Row from 'src/components/camtransactions/Row';
import http from 'src/utils/api';
import { parseResponse } from 'src/utils/transactions';
import styles from 'src/components/camtransactions/styles';
import Svg from 'src/components/reusableComponents/Svg';
import ExportCamData from 'src/components/camtransactions/ExportCamData';
import Filter from 'src/components/reusableComponents/Filter';
import { format } from 'date-fns';
import { useUser } from 'src/context/userContext';

export default function CamTransactions() {
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [order, setOrder] = useState({ id_op: -1, date: 1 });

  const { userType } = useUser();

  useEffect(() => {
    const getTransactions = async () => {
      try {
        const response = await http.get('api/ResponseOBDList/');
        var parsedData = parseResponse(response);
        setTransactions(parsedData);
      } catch (error) {
        setTransactions([]);
        console.log(error);
      }
    };
    getTransactions();
  }, []);

  useEffect(() => {
    getFilterTransactions(new Date());
  }, [transactions]);

  const getFilterTransactions = (newDate) => {
    const dateMonth = format(new Date(newDate), 'MMM');
    const dateYear = format(new Date(newDate), 'yyyy');
    const filterResponse = transactions.filter((transaction) => {
      const { date } = transaction;
      const formatedDate = date.toString();
      return formatedDate.includes(dateMonth) && formatedDate.includes(dateYear);
    });
    setFilteredTransactions(filterResponse);
  };

  const getFilterAdvTransactions = (fromToDate) => {
    const filterResponse = transactions.filter((transaction) => {
      const { date } = transaction;
      return isWithinInterval(date, { start: fromToDate[0], end: fromToDate[1] });
    });
    setFilteredTransactions(filterResponse);
  };

  const handleCleanFilters = () => {
    setFilteredTransactions(transactions);
  };

  const sortByKey = (prop, data) => {
    if (!data || !prop) return;
    const [...sortedData] = data;
    sortedData.sort((a, b) => {
      const parsedKey1 = b[prop];
      const parsedKey2 = a[prop];
      let compare = 0;

      if (!parsedKey1 || !parsedKey2) return;

      if (parsedKey1 < parsedKey2) {
        compare = -1;
      }
      if (parsedKey1 > parsedKey2) {
        compare = 1;
      }
      return compare * order[prop];
    });

    setFilteredTransactions(sortedData);
    setOrder({ ...order, [prop]: order[prop] * -1 });
  };

  const parseExcelData = (data) => {
    return data.reduce((acc, curr) => {
      const { id_op, dni, date, data } = curr;
      var mergedInfo = data.map((row) => {
        var array = [id_op, new Date(date).toLocaleDateString(), dni, row.endpoint, row.id_empresa];
        // TODO: alguna manera mas elegante de chequear la existencia
        try {
          array = [ ...array, ...Object.values(row.details.extracted_data)];
        } catch (error) {
          ;
        }
        return array;
      });

      return [...acc, ...mergedInfo];
    }, []);
  };

  return (
    <>
      <Filter
        type="transactions"
        buttonTitle="Mostrar todos los registros"
        onSelectDate={getFilterTransactions}
        onSelectDateAdv={getFilterAdvTransactions}
        onClean={handleCleanFilters}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          maxWidth: {
            xs: '100%',
            md: 700,
          },
        }}
      >
        <ExportCamData data={parseExcelData(filteredTransactions)} user={userType} />
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          maxWidth: {
            xs: '100%',
            md: 700,
          },
          overflowX: 'auto',
        }}
      >
        {!transactions.length ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 2,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ cursor: 'pointer' }}
                  align="center"
                  onClick={() => sortByKey('id_op', filteredTransactions)}
                >
                  <styles.ContentCell>
                    ID&nbsp;
                    <Svg name="arrowDouble" size={13} />
                  </styles.ContentCell>
                </TableCell>
                <TableCell align="center">
                  <styles.ContentCell>Fecha</styles.ContentCell>
                </TableCell>
                <TableCell align="center">Endpoints</TableCell>
                <TableCell align="center">DNI</TableCell>
                <TableCell align="center">Control</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!filteredTransactions.length ? (
                <TableRow>
                  <TableCell>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        p: 2,
                      }}
                    >
                      <em>no hay transacciones</em>
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                filteredTransactions.map((transaction) => (
                  <Row key={transaction.id_op} {...transaction} />
                ))
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </>
  );
}
