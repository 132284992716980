import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Chart from 'src/components/reusableComponents/Chart';
import Autocomplete from 'src/components/reusableComponents/Autocomplete/Autocomplete';
import { ShowChart, BarChart } from '@mui/icons-material';
import { useUser } from 'src/context/userContext';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  useMediaQuery,
} from '@mui/material';
import baseconfig from './config/options';

const TotalByDay = ({ data, companies, loading, ...rest }) => {
  const [chart, setChart] = useState('bar');
  const [company, setCompany] = useState('');
  const [chartData, setChartData] = useState([]);
  const { userType } = useUser();
  const matches = useMediaQuery('(min-width:768px)');

  useEffect(() => {
    setChartData(data);
  }, [data]);

  useEffect(() => {
    if (company) {
      const selected = data.filter(({ name }) => name === company);
      setChartData(selected);
    } else {
      setChartData(data);
    }
  }, [company]);

  const categories = [...Array(31)].map((_, i) => i + 1);

  const xaxis = {
    categories,
    tickPlacement: 'on',
  };

  const barConfig = { ...baseconfig.bar, xaxis };
  const hBarConfig = { ...baseconfig.horiZontalBar, xaxis };
  const lineConfig = { ...baseconfig.line, xaxis };

  return (
    <Card {...rest}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 15px',
        }}
      >
        <CardHeader title="Total transacciones por día" />
        {userType === 'ADMIN_EC' && (
          <Autocomplete
            options={companies}
            size="small"
            label="Filtrar por empresa"
            value={company}
            setValue={setCompany}
          />
        )}
      </Box>
      <Divider />
      <CardContent sx={{ p: 1 }}>
        {!chartData.length || loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 2,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              height: {
                xs: 580,
                md: 450,
              },
              width: '100%',
              position: 'relative',
            }}
          >
            {chart === 'line' ? (
              <Chart options={lineConfig} series={chartData} type="line" />
            ) : null}
            {chart === 'bar' && matches ? (
              <Chart options={barConfig} series={chartData} type="bar" />
            ) : null}
            {chart === 'bar' && !matches ? (
              <Chart options={hBarConfig} series={chartData} type="bar" />
            ) : null}
          </Box>
        )}
      </CardContent>
      {matches && userType === 'ADMIN_EC' && (
        <Box
          sx={{
            display: 'flex',
            marginLeft: '30px',
            marginBottom: '20px',
            alignItems: 'center',
          }}
        >
          <ShowChart
            sx={{
              cursor: 'pointer',
              margin: '3px',
              color: chart === 'line' ? 'primary.main' : 'text.secondary',
            }}
            onClick={() => setChart('line')}
          />
          <BarChart
            onClick={() => setChart('bar')}
            sx={{
              cursor: 'pointer',
              margin: '3px',
              color: chart === 'bar' ? 'primary.main' : 'text.secondary',
            }}
          />
        </Box>
      )}
      <Divider />
    </Card>
  );
};

TotalByDay.propTypes = {
  companies: PropTypes.any,
  data: PropTypes.array,
  loading: PropTypes.bool,
};

TotalByDay.defaultProps = {
  companies: [],
};

export default TotalByDay;
