import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { isLoggedIn } from 'src/utils/auth';
import GlobalStyles from 'src/components/GlobalStyles';
import { theme, getCustomTheme } from 'src/theme';
import routes from 'src/routes';
import { UserProvider } from './context/userContext';
import { useEffect, useState } from 'react';

const App = () => {
  const routing = useRoutes(routes(isLoggedIn()));

  const company = localStorage.getItem('company');
  const [customTheme, setCustomTheme] = useState(theme);

  useEffect(() => {
    const theme = getCustomTheme(company);
    setCustomTheme(theme);
  }, [company]);

  useEffect(() => {
    const country = localStorage.getItem('country');
    if (country === null) {
      localStorage.clear();
    }
  }, []);

  return (
    <UserProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={customTheme}>
          <GlobalStyles />
          {routing}
        </ThemeProvider>
      </StyledEngineProvider>
    </UserProvider>
  );
};

export default App;
