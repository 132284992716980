import styled from '@emotion/styled/macro';
import { Button, Grid } from '@mui/material';

const GridStyled = styled(Grid)`
  display: flex;
  justify-content: ${({ isbuttons }) => (isbuttons ? 'flex-end' : 'center')};
  max-width: ${({ size }) => size && size.width}px;
  min-width: ${({ size }) => size && size.width}px;
`;

const ButtonStyled = styled(Button)`
  width: 105px;
`;

export default {
  GridStyled,
  ButtonStyled,
};
