require('dotenv').config();
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from 'src/components/reusableComponents/ScrollToTop';
import * as serviceWorker from './serviceWorker';
import App from './App';

console.log('main');
console.log(process.env.NODE_ENV);

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop />
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.unregister();
