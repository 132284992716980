import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PropTypes from 'prop-types';
import Chart from 'src/components/reusableComponents/Chart';
import { fieldsColors, imagesColors } from './data';
import { useEffect, useState } from 'react';

const EndpointCard = ({ name, type, text, categories, series, loading }) => {
  const [existDataToRender, setExistDataToRender] = useState(true);
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  const areDataAvailable = () => {
    const areSomeNumber = series
      .reduce((acc, { data }) => acc.concat(data), [])
      .filter((currentData) => currentData !== 0);
    setExistDataToRender(Boolean(areSomeNumber.length));
  };

  useEffect(() => {
    areDataAvailable();
  }, [series]);

  const colors = type === 'fields' ? fieldsColors : imagesColors;
  const options = {
    chart: {
      stacked: true,
      stackType: '100%',
    },
    plotOptions: {
      bar: {
        horizontal: !mobileScreen && true,
      },
    },
    stroke: {
      width: 0.5,
      colors: ['#fff'],
    },
    xaxis: {
      categories,
    },
    tooltip: {
      y: {
        formatter(value) {
          return value;
        },
      },
    },
    fill: {
      opacity: 1,
      colors,
    },
    colors,
    legend: {
      position: 'top',
      horizontalAlign: 'center',
    },
  };

  return (
    <Card>
      <CardHeader
        title={name}
        action={
          <Tooltip title={text}>
            <HelpOutlineIcon
              color="action"
              fontSize="small"
              sx={{ marginTop: '4px', marginRight: '10px' }}
            />
          </Tooltip>
        }
        sx={{ textAlign: 'center' }}
      />
      <Divider />
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: 2,
          }}
        >
          <CircularProgress />
        </Box>
      ) : existDataToRender ? (
        <Chart
          options={options}
          series={series}
          type="bar"
          width={mobileScreen ? '300' : '500'}
          height={mobileScreen ? '400' : '350'}
          style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: 2,
          }}
        >
          <em>No se realizaron transacciones en este endpoint</em>
        </Box>
      )}
    </Card>
  );
};

EndpointCard.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  categories: PropTypes.array,
  series: PropTypes.array,
  loading: PropTypes.bool,
};

export default EndpointCard;
