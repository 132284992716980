// export const categories = {
//   document: 'DOCUMENTO',
//   name_insurance_company: 'NOMBRE DE LA EMPRESA ASEGURADORA',
//   name_insured: 'NOMBRE DEL ASEGURADO',
//   fecha_expedicion: 'FECHA DE EXPEDICIÓN',
//   lugar_expedicion: 'LUGAR DE EXPEDICIÓN',
//   valid_from: 'VIGENCIA DESDE',
//   valid_to: 'VIGENCIA HASTA',
//   barcode: 'BARCODE',
// };

export const categories = {
  document: {
    color: 'white',
    name: 'DOCUMENTO',
  },
  name_insurance_company: {
    color: 'red',
    name: 'NOMBRE DE LA EMPRESA ASEGURADORA',
  },
  name_insured: {
    color: 'yellow',
    name: 'NOMBRE DEL ASEGURADO',
  },
  cedula_insured: {
    color: 'yellow',
    name: 'CÉDULA DEL ASEGURADO',
  },
  fecha_expedicion: {
    color: 'green',
    name: 'FECHA DE EXPEDICIÓN',
  },
  lugar_expedicion: {
    color: 'blue',
    name: 'LUGAR DE EXPEDICIÓN',
  },
  valid_from: {
    color: 'orange',
    name: 'VIGENCIA DESDE',
  },
  valid_to: {
    color: 'brown',
    name: 'VIGENCIA HASTA',
  },
  barcode: {
    color: 'violet',
    name: 'BARCODE',
  },
  policy_number: {
    color: 'violet',
    name: 'NÚMERO DE PÓLIZA',
  },
  policy_value: {
    color: 'violet',
    name: 'VALOR DE LA PÓLIZA',
  },
  suc: {
    color: 'violet',
    name: 'SUCURSAL',
  },
  ramo: {
    color: 'violet',
    name: 'RAMO',
  },
};
