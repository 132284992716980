export const rows = [
  {
    id: '1',
    documentType: 'Póliza de Seguro',
    company: 'Seguros del Estado S.A.',
  },
  {
    id: '2',
    documentType: 'Póliza de Seguro',
    company: 'San Cristobal',
  },
  {
    id: '3',
    documentType: 'Póliza de Seguro',
    company: 'La Caja',
  },
  {
    id: '4',
    documentType: 'Póliza de Seguro',
    company: 'Sancor',
  },
  {
    id: '5',
    documentType: 'Póliza de Seguro',
    company: 'MAPFRE',
  },
];
