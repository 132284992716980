import { Camera, Inbox, UserCheck, Grid, BarChart2, Link } from 'react-feather';
import { TestModeProvider } from 'src/context/testModeContext';
import { TemplatesProvider } from 'src/context/templatesContext';
import Dashboard from 'src/pages/Dashboard';
import Stats from 'src/pages/Stats';
import Transactions from 'src/pages/Transactions';
import TestMode from 'src/pages/TestMode';
import TestModePolicy from 'src/pages/TestModePolicy';
import Details from 'src/pages/Details';
import Settings from 'src/pages/Settings';
import TemplatesSettings from 'src/pages/TemplatesSettings';
import AddTemplate from 'src/pages/AddTemplate';
import EditTemplate from 'src/pages/EditTemplate';
import CamTransactions from 'src/pages/CamTransactions';
import GenerateLinkAppCamera from 'src/pages/GenerateLinkAppCamera';
// import ChangePassword from 'src/pages/ChangePassword';
import Roles from 'src/routes/roles';
import Countries from 'src/routes/countries';

const privateRoutes = [
  {
    element: <Dashboard />,
    path: 'dashboard',
    title: 'Dashboard',
    icon: Grid,
    permission: [
      Roles.ADMIN_EC.value,
      Roles.ADMIN_EMPRESA.value,
      Countries.ARGENTINA.value,
      Countries.COLOMBIA.value,
    ],
  },
  {
    element: <Stats />,
    path: 'stats',
    title: 'Estadísticas',
    icon: BarChart2,
    permission: [
      Roles.ADMIN_EC.value,
      Roles.ADMIN_EMPRESA.value,
      Countries.ARGENTINA.value,
      Countries.COLOMBIA.value,
    ],
  },
  {
    element: <Transactions />,
    path: 'transactions',
    title: 'Transacciones',
    icon: Inbox,
    permission: [
      Roles.ADMIN_EC.value,
      Roles.ADMIN_EMPRESA.value,
      Roles.MINIONS.value,
      Countries.ARGENTINA.value,
      Countries.COLOMBIA.value,
    ],
  },
  {
    element: (
      <TestModeProvider>
        <TestMode />
      </TestModeProvider>
    ),
    path: 'testMode',
    title: 'ModoPrueba',
    icon: UserCheck,
    permission: [
      Roles.ADMIN_EC.value,
      Roles.ADMIN_EMPRESA.value,
      Roles.MINIONS.value,
      Countries.ARGENTINA.value,
    ],
  },
  {
    element: (
      <TestModeProvider>
        <Details />
      </TestModeProvider>
    ),
    path: 'details',
    title: 'Detalles',
    permission: [
      Roles.ADMIN_EC.value,
      Roles.ADMIN_EMPRESA.value,
      Roles.MINIONS.value,
      Countries.ARGENTINA.value,
      Countries.COLOMBIA.value,
    ],
  },
  {
    element: (
      <TestModeProvider>
        <TestMode />
      </TestModeProvider>
    ),
    path: 'testCedula',
    title: 'Cédula',
    permission: [
      Roles.ADMIN_EC.value,
      Roles.ADMIN_EMPRESA.value,
      Roles.MINIONS.value,
      Countries.COLOMBIA.value,
    ],
  },
  {
    element: (
      <TestModeProvider>
        <TestModePolicy />
      </TestModeProvider>
    ),
    path: 'testPolicy',
    title: 'PólizaSeguro',
    permission: [
      Roles.ADMIN_EC.value,
      Roles.ADMIN_EMPRESA.value,
      Roles.MINIONS.value,
      Countries.COLOMBIA.value,
    ],
  },
  {
    element: <Settings />,
    path: 'parameterSettings',
    title: 'Parámetros',
    permission: [
      Roles.ADMIN_EC.value,
      Roles.ADMIN_EMPRESA.value,
      Countries.ARGENTINA.value,
      Countries.COLOMBIA.value,
    ],
  },
  {
    element: (
      <TemplatesProvider>
        <TemplatesSettings />
      </TemplatesProvider>
    ),
    path: 'templatesSettings',
    title: 'Plantillas',
    permission: [
      Roles.ADMIN_EC.value,
      Roles.ADMIN_EMPRESA.value,
      Countries.ARGENTINA.value,
      Countries.COLOMBIA.value,
    ],
  },
  {
    element: (
      <TemplatesProvider>
        <AddTemplate />
      </TemplatesProvider>
    ),
    path: 'addTemplate',
    title: 'AñadirPlantilla',
    permission: [
      Roles.ADMIN_EC.value,
      Roles.ADMIN_EMPRESA.value,
      Countries.ARGENTINA.value,
      Countries.COLOMBIA.value,
    ],
  },
  {
    element: (
      <TemplatesProvider>
        <EditTemplate />
      </TemplatesProvider>
    ),
    path: 'editTemplate',
    title: 'Plantilla',
    permission: [
      Roles.ADMIN_EC.value,
      Roles.ADMIN_EMPRESA.value,
      Countries.ARGENTINA.value,
      Countries.COLOMBIA.value,
    ],
  },
  {
    element: <GenerateLinkAppCamera />,
    path: 'generateLink',
    title: 'Generar link App Camera',
    icon: Link,
    permission: [
      Roles.ADMIN_EC.value,
      Roles.ADMIN_EMPRESA.value,
      Countries.ARGENTINA.value,
      Countries.COLOMBIA.value,
    ],
  },
  {
    element: <CamTransactions />,
    path: 'camtransactions',
    title: 'Transacciones OBD',
    icon: Camera,
    permission: [
      Roles.ADMIN_EC.value,
      Roles.ADMIN_EMPRESA.value,
      Roles.MINIONS.value,
      Countries.ARGENTINA.value,
      Countries.COLOMBIA.value,
    ],
  },
  // {
  //   element: <ChangePassword />,
  //   path: 'changepassword',
  //   title: 'CambiarContraseña',
  //   permission: [
  //     Roles.ADMIN_EC.value,
  //     Roles.ADMIN_EMPRESA.value,
  //     Roles.MINIONS.value
  //   ]
  // }
];

export default privateRoutes;
