export function isLoggedIn() {
  return !!localStorage.getItem('role');
}

export function logout() {
  localStorage.clear();
}

export function isArrayWithLength(arr) {
  return Array.isArray(arr) && arr.length;
}

export function getAllowedRoutes(routes) {
  const role = localStorage.getItem('role');
  const country = localStorage.getItem('country');
  return routes.filter(({ permission }) => {
    if (!permission) return true;
    if (!isArrayWithLength(permission)) return true;
    return permission.includes(role && country);
  });
}

export function getItemByRole(routes) {
  const role = localStorage.getItem('role');
  const country = localStorage.getItem('country');
  const itemsByRole = [];
  routes.forEach(({ path, title, permission, icon }) => {
    if (permission.includes(role && country)) {
      if (
        title !== 'Detalles' &&
        title !== 'CambiarContraseña' &&
        title !== 'AñadirPlantilla' &&
        title !== 'Plantilla'
      ) {
        itemsByRole.push({ href: `/app/${path}`, title, icon });
      }
    }
  });
  return itemsByRole;
}
