/* eslint-disable prettier/prettier */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactPictureAnnotation } from 'src/tool/react-picture-annotation';
import { getInputElement } from './components/inputElement.js';
import { getShapeStyleFunc } from './styles.js';

const PictureAnnotation = ({
  image,
  size,
  coordinates,
  setCoordinates,
  activeAnnotation,
  setActiveAnnotation,
  availableTags,
  nameTags,
  setNameTags,
  editTemplate,
  editDocument,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [newTag, setNewTag] = useState('');

  const handleChange = (data) => {
    if (editTemplate) {
      if (editDocument) {
        const newData = data.filter((item) => {
          if (item.comment === 'document') {
            return item;
          }
        });
        setCoordinates(newData);
      } else {
        const newData = data.map((item) => {
          if (item.comment) {
            return item;
          } else {
            if (item.comment !== undefined) {
              return item;
            } else {
              if (item.mark.height >= 0 && item.mark.width >= 0) {
                return {
                  ...item,
                  comment: '',
                  mark: {
                    ...item.mark,
                    height: item.mark.height > 30 ? item.mark.height : 30,
                    width: item.mark.width > 200 ? item.mark.width : 200,
                  },
                };
              } else if (item.mark.height > 0 && item.mark.width < 0) {
                return {
                  ...item,
                  comment: '',
                  mark: {
                    ...item.mark,
                    height: Math.abs(item.mark.height),
                    width: Math.abs(item.mark.width),
                    x: item.mark.x - Math.abs(item.mark.width),
                  },
                };
              } else if (item.mark.height < 0 && item.mark.width > 0) {
                return {
                  ...item,
                  comment: '',
                  mark: {
                    ...item.mark,
                    height: Math.abs(item.mark.height),
                    width: Math.abs(item.mark.width),
                    y: item.mark.y - Math.abs(item.mark.height),
                  },
                };
              } else {
                return {
                  ...item,
                  comment: '',
                  mark: {
                    ...item.mark,
                    height: Math.abs(item.mark.height),
                    width: Math.abs(item.mark.width),
                    x: item.mark.x - Math.abs(item.mark.width),
                    y: item.mark.y - Math.abs(item.mark.height),
                  },
                };
              }
            }
          }
        });
        setCoordinates(newData);
      }
    } else {
      setActiveAnnotation(null);
    }
  };

  const handleSelect = (selectedId) => {
    setActiveAnnotation(selectedId);
  };

  return (
    <ReactPictureAnnotation
      image={image}
      width={size.width}
      height={size.height}
      annotationData={editTemplate ? coordinates : []}
      onSelect={handleSelect}
      onChange={handleChange}
      inputElement={getInputElement(
        availableTags,
        nameTags,
        setNameTags,
        isOpen,
        setIsOpen,
        isError,
        setIsError,
        newTag,
        setNewTag,
        editDocument
      )}
      scrollSpeed={0}
      selectedId={activeAnnotation}
      getAnnotationStyle={getShapeStyleFunc()}
    />
  );
};

PictureAnnotation.propTypes = {
  image: PropTypes.string.isRequired,
  size: PropTypes.object.isRequired,
  coordinates: PropTypes.array.isRequired,
  setCoordinates: PropTypes.func.isRequired,
  activeAnnotation: PropTypes.string,
  setActiveAnnotation: PropTypes.func.isRequired,
  availableTags: PropTypes.object.isRequired,
  nameTags: PropTypes.object.isRequired,
  setNameTags: PropTypes.func.isRequired,
  editTemplate: PropTypes.bool.isRequired,
  editDocument: PropTypes.bool.isRequired,
};

export default PictureAnnotation;
