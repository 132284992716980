import styled from '@emotion/styled/macro';
import { IconButton, TableCell, TableRow } from '@mui/material';

const Cell = styled(TableCell)`
  padding: 10px;
  text-align: center;
  border-bottom: 0;
  @media (max-width: 768px) {
    padding: 12px 15px;
    font-size: 14px;
  }
`;

const ExpandCell = styled(Cell)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  @media (max-width: 768px) {
    padding-left: 10px;
    padding-bottom: 11px;
    margin-bottom: 2px;
  }
`;

const Row = styled(TableRow)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ExpandIcon = styled(IconButton)`
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const CardData = styled.div`
  // padding: 10px
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const ContentCell = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  @media (max-width: 768px) {
    font-size: 13px;
    width: 90%;
  }
`;

const ExpandedContent = styled.div`
  padding: 0 0 0 10px;
  backgroundcolor: #eeeeee;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: 2000px;
  justifycontent: space-evenly;
  max-height: ${({ isExpanded }) => !isExpanded && '0px'};
  transition: ${({ isExpanded }) =>
    isExpanded ? 'max-height 1s ease-in-out' : 'max-height 0.5s cubic-bezier(0, 1, 0, 1)'};
`;

export default {
  Row,
  ExpandIcon,
  Cell,
  CardData,
  ButtonsContainer,
  ContentCell,
  ExpandedContent,
  ExpandCell,
};
