import img from 'src/components/templatesSettings/poliza.jpg';

export const api = {
  id: 1,
  type_file: 'insurance_policy',
  file: {
    title: 'PLANTILLA',
    src: img,
    size: {
      width: 900,
      height: 1200,
    },
    annotations: {
      document: [
        {
          id: 'zp6k2n',
          mark: {
            x: 81.22222222222221,
            y: 76.22222222222226,
            width: 1569.6666666666667,
            height: 2047.5555555555557,
            type: 'RECT',
          },
          comment: 'document',
        },
      ],
      tags: [
        {
          id: 'zNHA2W',
          mark: {
            x: 219.11111111111111,
            y: 431.33333333333337,
            width: 364.55555555555554,
            height: 28.333333333333314,
            type: 'RECT',
          },
          comment: 'name_insured',
        },
        {
          id: 'zGTH6x',
          mark: {
            x: 899.1111111111111,
            y: 314.22222222222223,
            width: 281.44444444444434,
            height: 43.44444444444446,
            type: 'RECT',
          },
          comment: 'valid_from',
        },
        {
          id: 'CW2xxF',
          mark: {
            x: 1188.111111111111,
            y: 314.22222222222223,
            width: 279.55555555555566,
            height: 47.22222222222223,
            type: 'RECT',
          },
          comment: 'valid_to',
        },
        {
          id: 'kaBaRM',
          mark: {
            x: 111.44444444444446,
            y: 110.22222222222226,
            width: 334.3333333333333,
            height: 122.77777777777777,
            type: 'RECT',
          },
          comment: 'name_insurance_company',
        },
        {
          id: 'ENMZGQ',
          mark: {
            x: 691.3333333333334,
            y: 314.22222222222223,
            width: 202.1111111111111,
            height: 45.33333333333337,
            type: 'RECT',
          },
          comment: 'fecha_expedicion',
        },
        {
          id: 'xJ8H2Q',
          mark: {
            x: 105.77777777777777,
            y: 582.4444444444445,
            width: 190.77777777777777,
            height: 54.77777777777783,
            type: 'RECT',
          },
          comment: 'lugar_expedicion',
        },
        {
          id: 'tCsskM',
          mark: {
            x: 736.6666666666666,
            y: 1575.9999999999998,
            width: 721.5555555555555,
            height: 124.66666666666674,
            type: 'RECT',
          },
          comment: 'barcode',
        },
      ],
    },
  },
};

export const apiCrop = {
  id: 1,
  type_file: 'insurance_policy',
  file: {
    title: 'PLANTILLA',
    src: img,
    size: {
      width: 850,
      height: 1100,
    },
    annotations: {
      document: [
        {
          id: 'zp6k2n',
          mark: {
            x: 81.22222222222221,
            y: 76.22222222222226,
            width: 1569.6666666666667,
            height: 2047.5555555555557,
            type: 'RECT',
          },
          comment: 'document',
        },
      ],
      tags: [
        {
          id: 'tCsskM',
          mark: {
            x: 640.7199999999999,
            y: 1488.62,
            width: 721.5555555555555,
            height: 124.66666666666674,
            type: 'RECT',
          },
          comment: 'barcode',
        },
        {
          id: 'Q4fzZb',
          mark: {
            x: 1182,
            y: 110,
            width: 64,
            height: 38,
            type: 'RECT',
          },
          comment: 'ramo',
        },
        {
          id: '8GMzSj',
          mark: {
            x: 1264,
            y: 112,
            width: 270,
            height: 38,
            type: 'RECT',
          },
          comment: 'policy_number',
        },
        {
          id: 'hXiCXs',
          mark: {
            x: 1100,
            y: 112,
            width: 66,
            height: 36,
            type: 'RECT',
          },
          comment: 'suc',
        },
        {
          id: 'iQcmXt',
          mark: {
            x: 1310,
            y: 272,
            width: 126,
            height: 34,
            type: 'RECT',
          },
          comment: 'cedula_insured',
        },
        {
          id: '8m5SPm',
          mark: {
            x: 10,
            y: 1260,
            width: 220,
            height: 28,
            type: 'RECT',
          },
          comment: 'policy_value',
        },
        {
          id: 'zGTH6x',
          mark: {
            x: 808.3044444444444,
            y: 230,
            width: 281.44444444444434,
            height: 36.860000000000014,
            type: 'RECT',
          },
          comment: 'valid_from',
        },
        {
          id: 'CW2xxF',
          mark: {
            x: 1095.591111111111,
            y: 232,
            width: 279.55555555555566,
            height: 34,
            type: 'RECT',
          },
          comment: 'valid_to',
        },
        {
          id: 'zNHA2W',
          mark: {
            x: 124,
            y: 342.24,
            width: 355.1533333333333,
            height: 28.333333333333314,
            type: 'RECT',
          },
          comment: 'name_insured',
        },
      ],
    },
  },
};
