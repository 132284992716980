import { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckContained from '@mui/icons-material/CheckCircle';
import CloseContained from '@mui/icons-material/Cancel';
import { codes_responses } from 'src/utils/transactions';
import { isObject, isArray, isBoolean } from 'src/utils/helper';
import styles from './styles';
 

function Content({ endpointData }) {
  const [expanded, setExpanded] = useState({
    dni_front: false,
    dni_back: false,
    data_validation_renaper_response: false,
    extracted_data: false,
    emotions: false,
  });

  const displayExpanded = (prop) => {
    if (prop in expanded) {
      return expanded[prop];
    }
    return false;
  };

  const handleExpanded = (prop) => {
    if (prop in expanded) {
      return setExpanded({ ...expanded, [prop]: !expanded[prop] });
    }
    return null;
  };

  const suspiciousIcon = (prop, value) => {
    if (prop === 'suspicious') return !value;
    return value;
  };

  return (
    <Box>
      {Object.keys(endpointData).map((endpointKey, index) => (
        <Fragment key={`${endpointKey}-${index}`}>
          {isObject(endpointData[endpointKey]) && (
            <Box
              key={`${endpointKey}-${index}`}
              sx={{ border: '1px solid rgba(224, 224, 224, 1)', borderRadius: 1, m: 2 }}
            >
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  m: 1,
                  justifyContent: 'space-between',
                }}
                variant="h6"
                onClick={() => handleExpanded(endpointKey)}
              >
                {`${codes_responses[endpointKey] || endpointKey}`}
                {endpointKey in expanded &&
                  (expanded[endpointKey] ? (
                    <KeyboardArrowUpIcon color="action" fontSize="small" />
                  ) : (
                    <KeyboardArrowDownIcon color="action" fontSize="small" />
                  ))}
              </Typography>
              <styles.ExpandedContent isExpanded={displayExpanded(endpointKey)}>
                <Content endpointData={endpointData[endpointKey]} />
              </styles.ExpandedContent>
            </Box>
          )}
          {isArray(endpointData[endpointKey]) && (
            <>
              {endpointData[endpointKey].map((object, i) => (
                <Box key={i.toString()} sx={{ pb: 1, mb: 3 }}>
                  <Typography variant="h6" sx={{ pl: 2.5 }}>
                    {`Selfie ${i + 1}:`}
                  </Typography>
                  <Content endpointData={object} />
                </Box>
              ))}
            </>
          )}
          {!isArray(endpointData[endpointKey]) &&
            !isObject(endpointData[endpointKey]) &&
            endpointKey !== 'id_op' && 
            endpointKey !== 'id_conjunto_imagenes' && 
            endpointKey !== 'id_imagen' && 
            endpointKey !== 'id_endpoint' && 
            (
              <Box
                key={`${endpointKey}-${endpointData[endpointKey]}-${index}`}
                sx={{
                  p: 1,
                  border: '1px solid rgba(224, 224, 224, 1)',
                  borderRadius: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  m: 2,
                  flexWrap: 'wrap',
                }}
              >
                <Typography variant="h6" component="span">
                  {`${codes_responses[endpointKey] || endpointKey}: `}
                </Typography>
                {isBoolean(endpointData[endpointKey]) ? (
                  <>
                    {suspiciousIcon(endpointKey, endpointKey == 'ban_list_hit' ? !endpointData[endpointKey] : endpointData[endpointKey]) ? (
                      <CheckContained style={{ color: green[500] }} />
                    ) : (
                      <CloseContained style={{ color: red[500] }} />
                    )}
                  </>
                ) : (
                  <Typography variant="body2" component="span">
                    {endpointData[endpointKey] !== '' && endpointData[endpointKey] !== null ? (
                      endpointData[endpointKey]
                    ) : (
                      <em>sin datos</em>
                    )}
                  </Typography>
                )}
              </Box>
            )}           
        </Fragment>
      ))}
    </Box>
  );
}

Content.propTypes = {
  endpointData: PropTypes.any,
};

export default Content;
