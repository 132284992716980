export default {
  MuiCalendarPicker: {
    styleOverrides: {
      root: {
        maxHeight: '335px',
        '& .PrivatePickersSlideTransition-root': {
          minHeight: '240px',
        },
        '& .MuiMonthPicker-root': {
          margin: '0 auto',
        },
      },
    },
  },
  MuiMonthPicker: {
    styleOverrides: {
      root: {
        '& .MuiTypography-root': {
          height: '35px',
        },
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        '& .MuiInputBase-input': {
          padding: '8.5px 0 8.5px 10px',
        },
        '& .MuiFormLabel-root': {
          top: '-6px',
        },
      },
    },
  },
};
