export const gestures = [
  {
    code: 200,
    value: 'No evaluar gestos',
  },
  {
    code: 100,
    value: 'Neutra',
  },
  {
    code: 101,
    value: 'Guiñe el ojo izquierdo',
  },
  {
    code: 102,
    value: 'Guiñe el ojo derecho',
  },
  {
    code: 103,
    value: 'Abra la boca',
  },
  {
    code: 104,
    value: 'Guiñe ojo izquierdo y abra la boca',
  },
  {
    code: 105,
    value: 'Guiñe ojo derecho y abra la boca',
  },
  {
    code: 106,
    value: 'Guiñe un ojo',
  },
  {
    code: 107,
    value: 'Guiñe un ojo y abra la boca',
  },
  {
    code: 108,
    value: 'Sonría',
  },
  {
    code: 109,
    value: 'Guiñe un ojo y sonría',
  },
  {
    code: 110,
    value: 'Guiñe el ojo derecho y sonría',
  },
  {
    code: 111,
    value: 'Guiñe el ojo izquierdo y sonría',
  },
];
