import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import styled from '@emotion/styled/macro';
import Link from '@mui/icons-material/Link';
import Check from '@mui/icons-material/Check';
import http from 'src/utils/api';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const CardContainer = styled.div`
  display: flex;
  justify-content: ${({ mobile }) => mobile && 'center'};
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  background-color: ${colors.common.white};
  border-radius: 4px;
  padding: 30px;
  box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 2px 0 rgb(63 63 68 / 15%);
`;

const TextFieldStyled = styled(TextField)`
  margin-bottom: ${({ inputstyled }) => (inputstyled ? '40px' : '24px')};
  margin-top: 0px;
`;

const GenerateLinkAppCamera = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dni, setDni] = useState('');
  const [response, setResponse] = useState({ transaccion: '', url: '' });
  const [idOperacion, setIdOperacion] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [isTextCopied, setIsTextCopied] = useState(false);

  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  const resetForm = () => {
    setDni('');
    setResponse({ transaccion: '', url: '' });
    setIdOperacion('');
    setOpenDialog(false);
    setIsTextCopied(false);
  };

  const generateLink = async () => {
    try {
      setIsLoading(true);
      const headerConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const data = { dni, id_op_client: idOperacion };
      const responseData = await http.post('api/newlinkoperation/', data, headerConfig);
      setResponse(responseData);
      setOpenDialog(true);
    } catch (error) {
      alert(
        'Hubo un problema al generar el link. Por favor comuníquese con EntreConsultas. Muchas gracias'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    resetForm();
  };

  const copyText = () => {
    setIsTextCopied(true);
  };

  const isFormValid = () => dni.length >= 7 && dni.length < 9 && idOperacion.length > 0;

  return (
    <CardContainer mobile={mobileScreen}>
      <Card>
        <TextFieldStyled
          label="Documento"
          margin="normal"
          name="documento"
          type="text"
          variant="outlined"
          onChange={(e) => setDni(e.target.value)}
          value={dni}
        />
        <TextFieldStyled
          label="Id Operación"
          margin="normal"
          name="idOperacion"
          type="text"
          variant="outlined"
          onChange={(e) => setIdOperacion(e.target.value)}
          value={idOperacion}
          inputstyled="true"
        />
        <Button
          variant="contained"
          size="large"
          disabled={isLoading || !isFormValid()}
          onClick={generateLink}
          sx={{ padding: '15px 25px', fontSize: 14 }}
        >
          {isLoading ? <CircularProgress size={20} /> : 'Generar nuevo link'}
        </Button>
        <Dialog
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" sx={{ fontSize: '25px' }}>
            Link generado correctamente
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ fontWeight: 500 }}>
              URL:
              <br />
              <span>
                {response.url}
                {isTextCopied ? (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<Check />}
                    style={{
                      marginLeft: 20,
                      backgroundColor: '#21b6ae',
                      color: '#FFFFFF',
                    }}
                    disabled
                  >
                    Copiado
                  </Button>
                ) : (
                  <CopyToClipboard onCopy={copyText} text={response.url}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      startIcon={<Link />}
                      style={{ marginLeft: 20 }}
                    >
                      Copiar enlace
                    </Button>
                  </CopyToClipboard>
                )}
              </span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </CardContainer>
  );
};

export default GenerateLinkAppCamera;
