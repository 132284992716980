import { Box, Button, Divider, Drawer, Hidden, Icon, List, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserCheck, Settings } from 'react-feather';
import { getItemByRole, logout } from 'src/utils/auth';
import PropTypes from 'prop-types';
import privateRoutes from 'src/routes/privateRoutes';
import NavItem from './NavItem';

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const items = getItemByRole(privateRoutes);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSettings, setIsOpenSettings] = useState(false);
  const [user, setUser] = useState({
    logo_profile: '',
    name_profile: '',
  });

  useEffect(() => {
    const getProfileData = () => {
      const logo = localStorage.getItem('logo');
      const name = localStorage.getItem('username');
      setUser({
        logo_profile: logo || '/static/images/avatars/avatar.jpg',
        name_profile: name || '',
      });
    };
    getProfileData();
  }, []);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2,
        }}
      >
        <img
          style={{
            width: 64,
            height: 64,
          }}
          alt="logo_profile"
          src={user.logo_profile}
        />
        <Typography color="textPrimary" variant="h6" sx={{ mt: '10px', textAlign: 'center' }}>
          {`¡Bienvenido! ${user.name_profile}`}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map(
            ({ href, title, icon }) =>
              title !== 'Cédula' &&
              title !== 'PólizaSeguro' &&
              title !== 'Parámetros' &&
              title !== 'Plantillas' && (
                <NavItem
                  href={href}
                  key={title}
                  title={title}
                  icon={icon}
                  itemActive
                  open={isOpen}
                  onClick={(value) => setIsOpen(value)}
                  onClickSettings={(value) => setIsOpenSettings(value)}
                />
              )
          )}
          {items.map(({ title }) => title).includes('Cédula') && (
            <NavItem
              title="Modo Prueba"
              icon={UserCheck}
              endIcon
              options={items}
              open={isOpen}
              onClick={(value) => setIsOpen(value)}
            />
          )}
          {
            <NavItem
              title="Configuración"
              icon={Settings}
              endIcon
              options={items}
              isSettings
              open={isOpenSettings}
              onClickSettings={(value) => setIsOpenSettings(value)}
            />
          }
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{
          m: 2,
          p: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            pt: 2,
          }}
        >
          {/* <Button
            color="primary"
            component="a"
            href="/app/changepassword"
            variant="text"
            startIcon={<Icon>lock</Icon>}
            sx={{
              textTransform: 'none',
              justifyContent: 'flex-start',
              fontSize: '13px'
            }}
          >
            Cambiar contraseña
          </Button> */}
          <Button
            color="primary"
            variant="text"
            startIcon={<Icon>logout</Icon>}
            sx={{
              textTransform: 'none',
              justifyContent: 'flex-start',
              fontSize: '13px',
            }}
            onClick={handleLogout}
          >
            Cerrar sesión
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)',
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default DashboardSidebar;
