import { Navigate } from 'react-router-dom';
import Login from 'src/pages/Login';
import ForgotPassword from 'src/pages/ForgotPassword';
import Register from 'src/pages/Register';

const publicRoutes = [
  {
    element: <Login />,
    path: '/login',
  },
  {
    element: <ForgotPassword />,
    path: '/forgotpassword',
  },
  {
    element: <Register />,
    path: '/register',
  },
  {
    element: <Navigate to="/app" />,
    path: '/',
  },
];

export default publicRoutes;
