import axios from 'axios';
import https from 'https';
import baseURL from 'src/config/url';
import process from 'process';

const http = axios.create({
  timeout: 240000, 
  baseURL, 
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  })
});

http.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  const setting = { ...config };
  setting.headers = {
    Authorization: `Token ${token}`,
    'Content-Type': config.headers['Content-Type'] || 'multipart/form-data',
  };
  return setting;
});

http.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (
      error &&
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      localStorage.clear();
      window.location.href = '/login';
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default http;
