import { useState, useEffect } from 'react';
import { Outlet, useLocation, Navigate } from 'react-router-dom';
import { experimentalStyled, Typography, useMediaQuery } from '@mui/material';
import { useUser } from 'src/context/userContext';
import privateRoutes from 'src/routes/privateRoutes';
import HelmetComponent from 'src/components/reusableComponents/Helmet/Helmet';
import { getTitleByRoute } from 'src/utils/helper';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

const DashboardLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  width: '100%',
}));

const DashboardLayoutWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 256,
  },
}));

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
});

const DashboardLayoutContent = experimentalStyled('div')(({ theme }) => ({
  flex: '1 1 auto',
  padding: '15px',
  height: '100%',
  [theme.breakpoints.up('lg')]: {
    padding: '25px',
  },
}));

const DashboardLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [pageTitle, setPageTitle] = useState('');
  const { setUserType } = useUser();
  const { pathname } = useLocation();
  const role = localStorage.getItem('role');
  const matches = useMediaQuery('(max-width:768px)');

  useEffect(() => {
    setUserType(role);
  }, [role]);

  useEffect(() => {
    const match = getTitleByRoute(privateRoutes, pathname);
    setPageTitle(match);
  }, [pathname]);

  if (pathname === '/app' || pathname === '/app/') {
    if (role === 'MINIONS') return <Navigate to="/app/transactions" />;
    return <Navigate to="dashboard" />;
  }

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <DashboardSidebar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            {pathname !== '/app' && <HelmetComponent pathName={pathname} />}
            {pathname !== '/app/details' && (
              <Typography
                align="left"
                color="textPrimary"
                gutterBottom
                variant={matches ? 'h5' : 'h3'}
                sx={{
                  mb: '30px',
                  textAlign: {
                    xs: 'center',
                    md: 'left',
                  },
                }}
              >
                {pageTitle}
              </Typography>
            )}
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
