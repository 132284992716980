export default [
  {
    id: 'dni_front',
    properties: {
      dni_side: 'DNI Side',
      nombres: 'Nombres',
      apellidos: 'Apellidos',
      dni_numero: 'DNI Número',
      ejemplar: 'Ejemplar',
      sexo: 'Sexo',
      fecha_nacimiento: 'Fecha de Nac.',
      fecha_emision: 'Fecha Emisión',
      fecha_vencimiento: 'Fecha de Vto',
      nacionalidad: 'Nacionalidad',
      numero_tramite: 'N° de Tramite',
      readable_barcode: 'Barcode Legible',
      match_ocr_barcode: 'Match OCR-Barcode',
      match_front_back: 'Match de datos Frente-Dorso',
      suspicious: 'Sospechoso',
      ban_list_hit: 'Blacklist',
    },
  },
  {
    id: 'dni_back',
    properties: {
      dni_side: 'DNI Side',
      dni_numero2: 'DNI Número',
      cuil: 'CUIL',
      domicilio: 'Domicilio',
      lugar_nacimiento: 'Lugar Nacimiento',
      fecha_ingreso: 'Fecha Ingreso',
      categoria_ingreso: 'Categoria Ingreso',
      expediente: 'Expediente',
      disposicion: 'Disposición',
      fecha_radicacion: 'Fecha Radicación',
      match_front_back_faces: 'Match de rostros Frente-dorso',
      codigo_dorso: 'Código Dorso',
      suspicious: 'Sospechoso',
    },
  },
  {
    id: 'selfie',
    properties: {
      code: 'Código',
      proof: 'Prueba de vida',
      match: 'Match',
      suspicious: 'Sospechosa',
      emotions: 'Emociones',
      context_match: 'Contexto',
      renaper_status: 'Renaper OK',
      renaper_confidence: 'Renaper confianza',
      renaper_faceAuthenticationId: 'Id Operación Renaper',
    },
  },
  {
    id: 'selfie_gesture',
    properties: {
      code: 'Código',
      proof: 'Prueba de vida',
      match: 'Match',
      suspicious: 'Sospechosa',
      emotions: 'Emociones',
      context_match: 'Contexto',
    },
  },
];
