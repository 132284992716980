export const getNewConfig = (setting, clientSettings, companySettings = null) => {
  const newConfig = setting.config.map((object) => {
    if (clientSettings) {
      for (const elementResp in clientSettings) {
        object.variables.forEach((element) => {
          if (object.name !== 'advancedSettings') {
            if (elementResp === element.id) {
              const variable = element;
              variable.isActive = clientSettings[elementResp];
            }
          }
        });
        if (object.subVariables) {
          object.subVariables.forEach((element) => {
            if (elementResp === element.id) {
              const subVariable = element;
              subVariable.isActive = clientSettings[elementResp];
            }
          });
        }
      }
    }
    if (object.name === 'advancedSettings') {
      if (companySettings) {
        for (const elementRespApi in companySettings) {
          object.variables.forEach((element) => {
            if (elementRespApi === element.id) {
              const variable = element;
              variable.value = companySettings[elementRespApi];
            }
          });
        }
      }
    }
    return object;
  });
  return newConfig;
};

export const getSettingChanges = (config, id, event) => {
  const newConfig = config.map((object) => {
    if (object.subVariables && id !== 'controlok') {
      object.subVariables.forEach((element) => {
        if (id === element.id) {
          const subVariable = element;
          subVariable.isActive = event.target.checked;
        }
      });
    } else {
      object.variables.forEach((element) => {
        if (id === element.id) {
          if (object.name !== 'advancedSettings') {
            const variable = element;
            variable.isActive = event.target.checked;
          } else {
            const variable = element;
            variable.value = event.target.value;
          }
        }
      });
    }
    return object;
  });
  return newConfig;
};

export const formatSetting = (setting) => {
  const { id_company: id, config } = setting;
  const configData = {};
  configData.id_empresa = id;
  config.forEach(({ name, variables, subVariables }) => {
    if (name !== 'advancedSettings') {
      variables.forEach((element) => {
        configData[element.id] = element.isActive;
      });
      if (subVariables) {
        subVariables.forEach((element) => {
          configData[element.id] = element.isActive;
        });
      }
    }
  });
  return configData;
};

export const formatAdvancedSetting = (setting, advancedSetting) => {
  const { config } = setting;
  const configData = {};
  for (const elementConfig in advancedSetting) {
    configData[elementConfig] = advancedSetting[elementConfig];
  }
  config.forEach(({ name, variables }) => {
    if (name === 'advancedSettings') {
      variables.forEach(({ id, value }) => {
        for (const element in configData) {
          if (element === id) {
            configData[element] = value;
          }
        }
      });
    }
  });
  return configData;
};

export const compareSetting = (config, newConfig) => {
  let changes = false;
  for (const element in config) {
    for (const newElement in newConfig) {
      if (element === newElement) {
        if (config[element] !== newConfig[newElement]) {
          changes = true;
        }
      }
    }
  }
  return changes;
};
