import PropTypes from 'prop-types';
import Charts from 'react-apexcharts';
import styled from '@emotion/styled/macro';
import { CardContent } from '@mui/material';

const ChartStyled = styled(Charts)`
  .apexcharts-menu {
    font-family: sans-serif;
  }
`;

const Chart = ({ series, options, type, ...rest }) => (
  <CardContent
    sx={{
      p: 0,
      height: '100%',
      width: '100%',
    }}
  >
    <ChartStyled
      options={options}
      series={series}
      type={type}
      width="100%"
      height="100%"
      {...rest}
    />
  </CardContent>
);

Chart.propTypes = {
  options: PropTypes.any,
  series: PropTypes.any,
  type: PropTypes.any,
};

export default Chart;
