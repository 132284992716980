import styled from '@emotion/styled';
import { css } from '@emotion/react';

const fill = css`
  fill: currentColor;
`;

const Wrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => size}px;
  // height: ${({ size }) => size}px;
  height: auto;
  color: ${({ color }) => color};

  & svg path {
    ${({ color }) => color && fill};
  }

  & svg {
    width: 100%;
    height: auto;
  }
`;

export default Wrapper;
