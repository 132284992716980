export const initialStateCedula = {
  id_op: '',
  type_cedula: '',
  control_ok: '',
  images: [
    {
      id: 'cedula_front',
      title: 'CÉDULA FRENTE',
      src: '',
      validation: {},
      img_quality: '',
    },
    {
      id: 'cedula_back',
      title: 'CÉDULA DORSO',
      src: '',
      validation: {},
      img_quality: '',
    },
    {
      id: 'selfie',
      title: 'SELFIE',
      src: '',
      validation: {},
      img_quality: '',
    },
    {
      id: 'selfie_gesture',
      title: 'SELFIE + GESTO',
      src: '',
      validation: {},
      img_quality: '',
    },
  ],
};
