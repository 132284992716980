import styled from '@emotion/styled/macro';
import { Box, Button, MenuItem, Select } from '@mui/material';
import { Trash2 as Icon } from 'react-feather';

const BoxStyled = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 220px;
`;

const SelectStyled = styled(Select)`
  background-color: #5664d2;
  color: #ffffff;
  border-top-right-radius: ${({ editdocument }) => (editdocument === 'true' ? '4px' : '0px')};
  border-bottom-right-radius: ${({ editdocument }) => (editdocument === 'true' ? '4px' : '0px')};

  .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    color: #ffffff;
  }

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-width: 1px !important;
  }
`;

const MenuItemStyled = styled(MenuItem)`
  justify-content: center;
`;

const ButtonStyled = styled(Button)`
  background-color: #dc3545;
  height: 40px;
  min-width: 43px;
  border-radius: 0px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  :hover {
    background-color: #dc3545;
  }
`;

const IconStyled = styled(Icon)`
  color: #ffffff;
`;

export default {
  BoxStyled,
  SelectStyled,
  MenuItemStyled,
  ButtonStyled,
  IconStyled,
};
