import { Box, Card, CardHeader, Grid } from '@mui/material';
import styled from '@emotion/styled/macro';

const GridStyled = styled(Grid)`
  margin-left: 0px;

  @media (max-width: 500px) {
    margin-left: -16px;
    margin-top: 0px;
  }
`;

const CardStyled = styled(Card)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  border-radius: 0px;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const BoxStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 15px;
  min-height: 280px;
`;

const CardHeaderStyled = styled(CardHeader)`
  margin-bottom: 10px;
  padding: 0px;
`;

const WrapperStyled = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  width: 120px;
  min-width: 120px;
`;

const Image = styled.img`
  width: 100%;
`;

const BoxIconStyled = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default {
  GridStyled,
  CardStyled,
  BoxStyled,
  CardHeaderStyled,
  WrapperStyled,
  ImageContainer,
  Image,
  BoxIconStyled,
};
