import { createTheme, colors } from '@mui/material';
import shadows from './shadows';
import typography from './typography';
import components from './components';
import companyColors from './companyColors';

export const theme = createTheme({
  palette: {
    background: {
      default: '#F4F6F8',
      paper: colors.common.white,
    },
    primary: {
      contrastText: '#ffffff',
      main: '#5664d2',
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c',
    },
    alert: {
      success: '#4caf50',
      warning: '#ff9800',
      error: '#ef5350',
    },
  },
  shadows,
  typography,
  components,
});

export const getCustomTheme = (company) => {
  const customTheme = {
    ...theme,
  };
  if (company !== null) {
    customTheme.palette.primary.main = companyColors[company].color;
  }
  return customTheme;
};
