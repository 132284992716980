import PropTypes from 'prop-types';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import CloseIcon from '@mui/icons-material/Close';
import FeedIcon from '@mui/icons-material/FeedOutlined';
import { Box, Typography } from '@mui/material';

function DrawerHeader({ dni, idOp, endpoint, closeDrawer }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        position: 'sticky',
        backgroundColor: '#5664d2',
        p: { xs: 2, md: 3 },
        mb: 2,
        top: 0,
        width: '100%',
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            mb: '5px',
          }}
        >
          <PermIdentityIcon sx={{ color: '#fafafa', mr: '5px' }} />
          <Typography sx={{ color: '#fafafa' }} variant="h5" component="span">
            DNI:
          </Typography>
          <Typography sx={{ color: '#fff' }} variant="body2" component="span">
            &nbsp;
            {dni}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            mb: '5px',
          }}
        >
          <FeedIcon sx={{ color: '#fafafa', mr: '5px' }} />
          <Typography sx={{ color: '#fafafa' }} variant="h5" component="span">
            ID OPERACIÓN:
          </Typography>
          <Typography sx={{ color: '#fff' }} variant="body2" component="span">
            &nbsp;
            {idOp}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            mb: '5px',
          }}
        >
          <InsertLinkIcon sx={{ color: '#fafafa', mr: '5px' }} />
          <Typography sx={{ color: '#fafafa' }} variant="h5" component="span">
            ENDPOINT:
          </Typography>
          <Typography sx={{ color: '#fff' }} variant="body2" component="span">
            &nbsp;
            {endpoint}
          </Typography>
        </Box>
      </Box>
      <CloseIcon onClick={closeDrawer()} sx={{ color: '#fafafa', cursor: 'pointer' }} />
    </Box>
  );
}

DrawerHeader.propTypes = {
  idOp: PropTypes.number,
  dni: PropTypes.string,
  endpoint: PropTypes.number,
  closeDrawer: PropTypes.func,
};

export default DrawerHeader;
