export const initialStateTemplate = {
  type_file: '',
  file: {
    title: 'PLANTILLA',
    src: '',
    srcCrop: '',
    size: {
      width: '',
      height: '',
    },
    sizeCrop: {
      width: '',
      height: '',
    },
    annotations: [],
    img_quality: '',
  },
};
