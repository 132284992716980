import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Chart from 'src/components/reusableComponents/Chart';
import { totalByCompany } from 'src/utils/chart';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  useMediaQuery,
} from '@mui/material';
import { colors } from './config/options';

const TotalByClient = ({ data, companies, loading, ...rest }) => {
  const [chartData, setChartData] = useState([]);
  const matches = useMediaQuery('(max-width:768px)');

  useEffect(() => {
    setChartData(totalByCompany(data));
  }, [data]);

  const options = {
    chart: {
      type: 'bar',
      id: 'bar',
      stacked: false,
      toolbar: {
        tools: {
          selection: false,
          pan: false,
          zoom: false,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
        distributed: true,
      },
    },
    colors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 0,
      colors: ['#fff'],
    },
    xaxis: {
      categories: companies,
      tickPlacement: 'on',
      labels: {
        show: true,
        hideOverlappingLabels: false,
        minHeight: 100,
        maxHeight: 200,
        rotate: -45,
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: matches ? '12px' : '9px',
        },
      },
    },
    legend: {
      show: false,
    },
  };

  return (
    <Card {...rest}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 15px',
        }}
      >
        <CardHeader title="Total onboardings por cliente" />
      </div>
      <Divider />
      <CardContent sx={{ p: 1 }}>
        {!chartData.length || loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 2,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              height: {
                xs: 580,
                md: 450,
                xl: 500,
              },
              width: '100%',
              position: 'relative',
            }}
          >
            <Chart
              options={options}
              series={[{ data: chartData }]}
              type="bar"
              width="100%"
              height="100%"
            />
          </Box>
        )}
      </CardContent>
      <Divider />
    </Card>
  );
};

TotalByClient.propTypes = {
  companies: PropTypes.any,
  data: PropTypes.any,
  loading: PropTypes.bool,
};

export default TotalByClient;
