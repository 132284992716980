export const colors = [
  '#084C61',
  '#DB3A34',
  '#FFC857',
  '#6877a4',
  '#F9844A',
  '#43AA8B',
  '#4D908E',
  '#90BE6D',
  '#F9C74F',
  '#43AA8B',
  '#577590',
  '#50514F',
  '#F8961E',
  '#323031',
  '#2E294E',
  '#f26419',
  '#9431b1',
  '#7f566d',
  '#387ad1',
];

const options = {
  bar: {
    chart: {
      type: 'bar',
      height: 300,
      id: 'bar',
      stacked: true,
      sparkline: {
        enabled: false,
      },
      toolbar: {
        tools: {
          selection: false,
          pan: false,
          zoom: false,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
      },
    },
    colors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 0,
      colors: ['#fff'],
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      itemMargin: {
        horizontal: 10,
        vertical: 10,
      },
    },
    tooltip: {
      theme: 'dark',
      intersect: true,
      shared: false,
      x: {
        show: false,
      },
    },
    zoom: {
      enabled: true,
      type: 'x',
      autoScaleYaxis: true,
      zoomedArea: {
        fill: {
          color: '#90CAF9',
          opacity: 0.4,
        },
        stroke: {
          color: '#0D47A1',
          opacity: 0.4,
          width: 1,
        },
      },
    },
  },
  horiZontalBar: {
    chart: {
      type: 'bar',
      id: 'bar',
      stacked: true,
      sparkline: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: '50%',
      },
    },
    colors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      itemMargin: {
        horizontal: 5,
        vertical: 5,
      },
    },
  },
  line: {
    chart: {
      height: 350,
      id: 'line',
      type: 'line',
      toolbar: {
        tools: {
          selection: false,
          pan: false,
          zoom: false,
        },
      },
    },
    colors,
    tooltip: {
      shared: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
      width: 1.6,
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
    },
    zoom: {
      enabled: true,
      type: 'x',
      autoScaleYaxis: true,
    },
  },
};

export default options;
