import styled from '@emotion/styled/macro';

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0px auto;
  border-radius: 4px;
  padding: 20px 0px;
`;

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export default {
  FilterWrapper,
  Container,
};
